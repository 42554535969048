export default class EventSystem {
  static events = {};

  static publish(event, data) {
    let queue = this.events[event];
    // console.log(event, data,queue);
    if (typeof queue === 'undefined') {
      return false;
    }

    queue.forEach(callback => {
      callback(data);
    });

    return true;
  }

  static subscribe(event, callback) {
    if (typeof this.events[event] === 'undefined') {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  }

  //  the callback parameter is optional. Without it the whole event will be removed, instead of
  // just one subscibtion. Enough for simple implementations
  static unsubscribe(event, callback) {
    let queue = this.events;

    if (typeof queue[event] !== 'undefined') {
      if (typeof callback === 'undefined') {
        delete queue[event];
      } else {
        this.events[event] = queue[event].filter(function (sub) {
          return sub !== callback;
        });
      }
    }
  }
}
