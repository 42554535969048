import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import React from 'react'
import EventSystem from '../helpers/EventSystem'

const TheHeaderDropdown = () => {

  const onLogoutClick = () => {
    EventSystem.publish("UNAUTHORIZED_ACTION");
  }

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        {/* <strong style={{ paddingLeft: "10px" }}> Hello {localStorage.getItem("USERNAME")}</strong> */}
        <div className="c-avatar">
          <CImg
            src={window.location.origin + "/avatars/user.jpeg"}
            className="c-avatar-img"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end" >
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>{localStorage.getItem("USERNAME")}</strong>
        </CDropdownItem>
        <CDropdownItem onClick={onLogoutClick}>
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
