import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import ProjectStageDetails from "./project-stage-details";

const ProjectStages = (props) => {
  // const [loading, setLoading] = useState(false);
  // const [loadingMessage, setLoadingMessage] = useState("Retrieving projects");
  const [entryModalShown, showEntryModal] = useState(false);
  const [projectStagesStatusList, setProjectStagesStatusList] = useState(
    props.projectStagesStatusList
  );

  const onDeleteClick = (clickedItem) => {
    const itemsCopy = [...props.projectItem.projectStages];
    itemsCopy.splice(itemsCopy.indexOf(clickedItem), 1);
    props.setProjectItem({
      ...props.projectItem,
      projectStages: itemsCopy,
    });
  };

  useEffect(() => {
    setProjectStagesStatusList(props.projectStagesStatusList);
  }, [props.projectStagesStatusList]);

  return (
    <>
      {entryModalShown ? (
        <CModal
          style={{ margin: "auto auto ", width: "70%" }}
          show={entryModalShown}
          size={"lg"}
          onClose={() => {
            showEntryModal(false);
          }}
        >
          <CModalHeader closeButton>
            <CModalTitle>Add new Sub-Project</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <ProjectStageDetails
              projectItem={props.projectItem}
              setProjectItem={props.setProjectItem}
              projectStagesStatusList={projectStagesStatusList}
              showEntryModal={showEntryModal}
            ></ProjectStageDetails>
          </CModalBody>
        </CModal>
      ) : (
        <></>
      )}
      {/* <LoadingOverlay active={loading} spinner text={loadingMessage}> */}
      <CRow>
        <CCol xs="12" sm="12">
          <CCard>
            {props.stepHeader ? props.stepHeader(props) : <></>}
            <CCardBody>
              <CRow style={{ paddingBottom: "inherit" }}>
                <CCol xs="12">
                  <CButton
                    type="button"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      showEntryModal(true);
                    }}
                  >
                    <CIcon
                      style={{
                        cursor: "pointer",
                        color: "white",
                      }}
                      name="cilPlus"
                    />{" "}
                    New Project Stage
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12">
                  <CDataTable
                    size="sm"
                    noItemsView={{
                      noResults: "No Available Project Stages",
                      noItems: "No Available Project Stages",
                    }}
                    footer
                    sorter={{ external: true, resetable: true }}
                    columnFilter
                    hover
                    striped
                    items={props.projectItem.projectStages}
                    fields={[
                      { key: "delete", label: "", _style: { width: "1%" } },
                      {
                        key: "stageDesc",
                        label: "Project stage description",
                      },
                      {
                        key: "plannedStartDate",
                        label: "Planned start date",
                      },
                      {
                        key: "actualStartDate",
                        label: "Actual start date",
                      },
                      { key: "plannedEndDate", label: "Planned end date" },
                      { key: "actualEndDate", label: "Actual end date" },
                    ]}
                    scopedSlots={{
                      delete: (item) => (
                        <td
                          style={{ textAlign: "center", color: "red" }}
                          onClick={(i) => onDeleteClick(item)}
                        >
                          <CIcon
                            style={{ cursor: "pointer" }}
                            name="cil-trash"
                          />
                        </td>
                      ),
                      plannedStartDate: (item) => (
                        <td style={{ textAlign: "center" }}>
                          {item.plannedStartDate
                            ? item.plannedStartDate
                            : "N/A"}
                        </td>
                      ),
                      plannedEndDate: (item) => (
                        <td style={{ textAlign: "center" }}>
                          {item.plannedEndDate ? item.plannedEndDate : "N/A"}
                        </td>
                      ),
                      actualStartDate: (item) => (
                        <td style={{ textAlign: "center" }}>
                          {item.actualStartDate ? item.actualStartDate : "N/A"}
                        </td>
                      ),
                      actualEndDate: (item) => (
                        <td style={{ textAlign: "center" }}>
                          {item.actualEndDate ? item.actualEndDate : "N/A"}
                        </td>
                      ),
                    }}
                  />
                </CCol>
              </CRow>
            </CCardBody>
            {props.stepFooter ? props.stepFooter(props) : <></>}
          </CCard>
        </CCol>
      </CRow>
      {/* </LoadingOverlay> */}
    </>
  );
};

export default ProjectStages;
