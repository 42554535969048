import CertificatesGenerator from './views/pages/certificates/Generator';
import ClientsList from './views/pages/clients/clients-list/clients-list';
import EmployeesList from './views/pages/employees/employees-list';
import Page401 from './views/pages/page401/Page401';
import ProjectDetail from './views/pages/projects/project-detail/project-detail';
import ProjectsList from './views/pages/projects/projects-list/projects-list';
import Form10List from './views/pages/vat/form10-list';
import Vat from './views/pages/vat/Vat';

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/vat', name: 'Vat', component: Vat },
  { path: '/form10', name: 'form10', component: Form10List },
  { path: '/certificates/generate', name: 'Certificate Generator', component: CertificatesGenerator },
  { path: '/project', exact: true, name: 'Projects', component: ProjectsList },
  { path: '/project/:id', exact: true, name: 'Project', component: ProjectDetail },
  { path: '/client', name: 'Clients', component: ClientsList },
  { path: '/employee', name: 'Employees', component: EmployeesList },
  { path: '/401', exact: true, component: Page401}
];

export default routes;
