import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import { updateSidebarShow } from "../redux/slicers/GlobalSlicer";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.globalSlicer.sidebarShow);
  const filteredNavs = useSelector(
    (state) => state.globalSlicer.filteredNavigations
  );
  return (
    <CSidebar
      show={show}
      minimize={true}
      onShowChange={(val) => {
        dispatch(updateSidebarShow(val));
      }}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        {/* <h1>AEC </h1> */}
        <img alt="" src="logo.png" className="c-sidebar-brand-full"></img>
        <img
          alt=""
          src="logoSmall.png"
          className="c-sidebar-brand-minimized"
        ></img>
        {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={filteredNavs}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
