import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CDataTable,
  CSelect,
  CPagination,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Select from "react-select";
import moment from "moment";
import { TaxAPI, ClientsAPI } from "../../../helpers/ApiHelper";
import LoadingOverlay from "react-loading-overlay";
import { base64ToBlob } from "../../../helpers/Utils";

const Vat = () => {
  // console.log("entered vat");
  const [activePage, setActivePage] = useState(1);
  const [captchaModalShown, showCaptchaModal] = useState(false);
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaDeText, setCaptchaDeText] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [totalNumber, setTotalNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [formDetails, setFormDetails] = useState({
    customerName: "",
    customerAddress: "",
    invoiceDate: "",
    productUnitPrice: "",
    invoiceNumber: "",
    customerUniqueTaxId: "",
  });
  const [formValidation, setFormValidation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Retrieving invoices");
  const [searchPeriod, setSearchPeriod] = useState(
    moment(new Date()).format("yyyy-MM")
  );
  const [invoiceList, setInvoiceList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [toasts, setToasts] = useState([]);

  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  const addToast = (title, message) => {
    setToasts([
      ...toasts,
      {
        position: "top-right",
        autohide: 3000,
        closeButton: true,
        fade: true,
        title: title,
        message: message,
      },
    ]);
  };

  const onResetClick = () => {
    setFormDetails({
      customerName: "",
      customerAddress: "",
      invoiceDate: "",
      productUnitPrice: "",
      invoiceNumber: "",
      customerUniqueTaxId: "",
    });
    setSelectedClient("");
    setFormValidation([]);
  };

  const validateFormField = (fieldName, fieldValue, isSubmit) => {
    let formValidationsArray = [...formValidation];
    if (!fieldValue || fieldValue.length === 0) {
      formValidationsArray.push(fieldName);
    } else {
      if (formValidationsArray.includes(fieldName)) {
        formValidationsArray.splice(formValidationsArray.indexOf(fieldName), 1);
      }
      // console.log(formValidationsArray);
    }
    if (!isSubmit) {
      setFormValidation(formValidationsArray);
    }
    return formValidationsArray;
  };

  const onAddClick = () => {
    // const invoice = {
    //   customerName: "mahmoud",
    //   customerAddress: "123",
    //   invoiceDate: moment(new Date()).format("DD/MM/yyyy"),
    //   productUnitPrice: "20",
    //   invoiceNumber: "02",
    //   customerUniqueTaxId: "100282784",
    // };
    let formValidationsArray = [];
    Object.entries(formDetails).forEach((item) => {
      // console.log("validating: ", item[0], item[1]);
      formValidationsArray = [
        ...formValidationsArray,
        ...validateFormField(item[0], item[1]),
      ];
    });
    if (formValidationsArray.length > 0) {
      setFormValidation(formValidationsArray);
      return false;
    }
    setLoading(true);
    setLoadingMessage("Adding new invoice");
    const requestForm = {
      ...formDetails,
      invoiceDate: moment(formDetails.invoiceDate).format("DD/MM/yyyy"),
    };
    // console.log(requestForm);
    TaxAPI.addVatInvoice(
      moment(searchPeriod).format("MM/yyyy"),
      activePage,
      itemsPerPage,
      requestForm
    )
      .then((data) => {
        // console.log('heree',data);
        if (data.status === 200) {
          setInvoiceList(
            data?.data?.data?.map((item) => ({
              ...item,
              invoiceDate: moment(
                new Date(
                  item.invoiceDate
                    .replaceAll("/", "")
                    .replace("Date(", "")
                    .replace(")", "") * 1
                )
              ).format("DD-MM-YYYY"),
            }))
          );
          setLoading(false);
          setTotalNumber(Math.ceil(data.data.total / itemsPerPage));
        } else {
          addToast("Failure", "Failed to add Invoice");
        }
      })
      .catch(error => {
        // console.log(error.response.status);
        if (error.response.status == 401) {
          TaxAPI.captcha()
            // .then(response => response.blob())
            .then((captchaResponse) => {
              console.log(captchaResponse.data);
              setCaptchaDeText(captchaResponse.data.captchaDeText);
              setVerificationToken(captchaResponse.data.verificationToken);
              const blob = base64ToBlob(captchaResponse.data.captchaImage);
              const url = URL.createObjectURL(blob);
              console.log(url);
              setCaptchaImageUrl(url);
              showCaptchaModal(true);
              setLoading(false);
            })

        } else {
          addToast("Failure", "Failed to add Invoice");
        }
        setLoading(false);
      });
  };

  const onDeleteClick = (clickedItem) => {
    setLoading(true);
    setLoadingMessage("Deleting invoice");
    TaxAPI.deleteVatInvoice(
      moment(searchPeriod).format("MM/yyyy"),
      activePage,
      itemsPerPage,
      clickedItem.invoiceID
    ).then((data) => {
      setInvoiceList(
        data?.data?.data?.map((item) => ({
          ...item,
          invoiceDate: moment(
            new Date(
              item.invoiceDate
                .replaceAll("/", "")
                .replace("Date(", "")
                .replace(")", "") * 1
            )
          ).format("DD-MM-YYYY"),
        }))
      );
      setLoading(false);
      setTotalNumber(Math.ceil(data.data.total / itemsPerPage));
    })
      .catch(error => {
        // console.log(error.response.status);
        if (error.response.status == 401) {
          TaxAPI.captcha()
            // .then(response => response.blob())
            .then((captchaResponse) => {

              setCaptchaDeText(captchaResponse.data.captchaDeText);
              setVerificationToken(captchaResponse.data.verificationToken);
              const blob = base64ToBlob(captchaResponse.data.captchaImage);
              const url = URL.createObjectURL(blob);
              console.log(url);
              setCaptchaImageUrl(url);
              showCaptchaModal(true);
              setLoading(false);
            })

        } else {
          addToast("Failure", "Failed to delete invoice");
        }
        setLoading(false);
      });;
  };

  useEffect(() => {
    setLoading(true);
    ClientsAPI.getClients().then((data) => {
      // console.log(data);
      const clientsList = data.data.map((item) => {
        return {
          key: item.clientId,
          value: item.arabicName,
          label: item.arabicName + " - " + item.englishName,
          object: item,
        };
      });
      setClientsList(clientsList);
    });
    console.log('eherereeee');
    TaxAPI.listVatInvoices(
      moment(searchPeriod).format("MM/yyyy"),
      activePage,
      itemsPerPage
    ).then((data) => {
      console.log('hereeeee', data);
      setInvoiceList(
        data?.data?.data?.map((item) => ({
          ...item,
          invoiceDate: moment(
            new Date(
              item.invoiceDate
                .replaceAll("/", "")
                .replace("Date(", "")
                .replace(")", "") * 1
            )
          ).format("DD-MM-YYYY"),
        }))
          .sort((item1, item2) => item1.invoiceNumber - item2.invoiceNumber)
      );
      setLoading(false);
      setTotalNumber(Math.ceil(data.data.total / itemsPerPage));
    })
      .catch(error => {
        // console.log(error.response.status);
        if (error.response.status == 401) {
          TaxAPI.captcha()
            // .then(response => response.blob())
            .then((captchaResponse) => {
              console.log(captchaResponse.data);
              setCaptchaDeText(captchaResponse.data.captchaDeText);
              setVerificationToken(captchaResponse.data.verificationToken);
              const blob = base64ToBlob(captchaResponse.data.captchaImage);
              const url = URL.createObjectURL(blob);
              console.log(url);
              setCaptchaImageUrl(url);
              showCaptchaModal(true);
              setLoading(false);
            })

        } else {
          addToast("Failure", "Failed to list Invoices");
        }
        setLoading(false);
      });
  }, [searchPeriod, activePage, itemsPerPage]);

  return (
    <>
      {captchaModalShown ? (
        <CModal
          style={{ margin: "auto auto", width: "25%" }}
          show={captchaModalShown}
          size={"lg"}
          onClose={() => showCaptchaModal(false)}
        >
          <CModalHeader closeButton>
            <CModalTitle>Enter Captcha for re-Authentication</CModalTitle>
          </CModalHeader>
          <CModalBody style={{ margin: "auto auto" }} >
            <img src={captchaImageUrl} alt="Dynamic from binary" />
            <CInput
              id="captchaCode"
              placeholder="Captcha Code"
              required
              value={captchaCode}
              onChange={(val) => {
                setCaptchaCode(val.target.value);
              }}
            />
            <CButton
              block
              shape="pill"
              // variant="outline"
              color="primary"
              className=""
              onClick={() => {
                TaxAPI.login1(captchaCode, { captchaText: captchaCode, captchaDeText: captchaDeText, verificationToken: verificationToken })
                  .then((data) => {
                    console.log(data.data);
                    setLoading(false);
                    showCaptchaModal(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Login
            </CButton>
          </CModalBody>
        </CModal>
      ) : (
        <></>
      )}
      <LoadingOverlay active={loading} spinner text={loadingMessage}>
        <CRow>
          <CCol sm="12" lg="6">
            {Object.keys(toasters).map((toasterKey) => (
              <CToaster position={toasterKey} key={"toaster" + toasterKey}>
                {toasters[toasterKey].map((toast, key) => {
                  return (
                    <CToast
                      key={"toast" + key}
                      show={true}
                      autohide={toast.autohide}
                      fade={toast.fade}
                    >
                      <CToastHeader closeButton={toast.closeButton}>
                        {toast.title}
                      </CToastHeader>
                      <CToastBody>
                        {toast.message}
                        {/* {`This is a toast in ${toasterKey} positioned toaster number ${key + 1}.`} */}
                      </CToastBody>
                    </CToast>
                  );
                })}
              </CToaster>
            ))}
          </CCol>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>Add a new Vat invoice</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="customerList"
                      >
                        Customer
                      </CLabel>
                      <Select
                        id="customerList"
                        showSearch={true}
                        placeholder="Select Customer"
                        isClearable
                        isSearchable
                        value={selectedClient}
                        onChange={(val) => {
                          // console.log(val);
                          setSelectedClient(val);
                          if (val) {
                            setFormDetails({
                              ...formDetails,
                              customerName: val.object.arabicName,
                              customerUniqueTaxId: val.object.taxId,
                              customerAddress: val.object.address,
                            });
                          } else {
                            setFormDetails({
                              ...formDetails,
                              customerName: "",
                              customerUniqueTaxId: "",
                              customerAddress: "",
                            });
                          }
                        }}
                        options={clientsList}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="customerName"
                      >
                        Customer name
                      </CLabel>
                      <CInput
                        id="customerName"
                        invalid={formValidation.indexOf("customerName") >= 0}
                        placeholder="Customer Name"
                        required
                        disabled
                        value={formDetails.customerName}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            customerName: val.target.value,
                          });
                          // console.log();
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="customerUniqueTaxId"
                      >
                        Customer Tax Id
                      </CLabel>
                      <CInput
                        id="customerUniqueTaxId"
                        placeholder="Customer Tax Id"
                        invalid={
                          formValidation.indexOf("customerUniqueTaxId") >= 0
                        }
                        required
                        type="number"
                        min="0"
                        disabled
                        value={formDetails.customerUniqueTaxId}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            customerUniqueTaxId: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="12">
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="customerAddress"
                      >
                        Customer Address
                      </CLabel>
                      <CInput
                        id="customerAddress"
                        placeholder="Customer Address"
                        invalid={formValidation.indexOf("customerAddress") >= 0}
                        required
                        disabled
                        value={formDetails.customerAddress}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            customerAddress: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="invoiceNumber"
                      >
                        Invoice number
                      </CLabel>
                      <CInput
                        id="invoiceNumber"
                        placeholder="Invoice Number"
                        invalid={formValidation.indexOf("invoiceNumber") >= 0}
                        required
                        type="number"
                        min="0"
                        value={formDetails.invoiceNumber}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            invoiceNumber: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="productUnitPrice"
                      >
                        Invoice Price
                      </CLabel>
                      <CInput
                        id="productUnitPrice"
                        placeholder="Invoice Price"
                        invalid={
                          formValidation.indexOf("productUnitPrice") >= 0
                        }
                        type="number"
                        min="0"
                        required
                        value={formDetails.productUnitPrice}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            productUnitPrice: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="invoiceDate"
                      >
                        Invoice Date
                      </CLabel>
                      <CInput
                        type="date"
                        id="invoiceDate"
                        name="invoiceDate"
                        invalid={formValidation.indexOf("invoiceDate") >= 0}
                        placeholder="invoiceDate"
                        max={moment(new Date()).format("yyyy-MM-DD")}
                        min="2020-01-01"
                        value={formDetails.invoiceDate}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            invoiceDate: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <CRow alignHorizontal="center">
                  <CCol xs="2" className="mb-3 mb-xl-0">
                    <CButton
                      block
                      shape="pill"
                      variant="outline"
                      color="primary"
                      className=""
                      onClick={onAddClick}
                    >
                      Add
                    </CButton>
                  </CCol>
                  <CCol xs="2" className="mb-3 mb-xl-0">
                    <CButton
                      block
                      shape="pill"
                      variant="outline"
                      color="secondary"
                      onClick={onResetClick}
                    >
                      Reset
                    </CButton>
                  </CCol>
                </CRow>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        {/* ################################# LIST OF INVOICES #################################  */}
        <CRow>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>
                <CRow alignVertical="center">
                  <CCol className="font-weight-bold">Invoices List</CCol>
                  <CCol>
                    <div className="card-header-actions">
                      <CInputGroup>
                        <CInputGroupPrepend>
                          <CInputGroupText
                            className={
                              "bg-transparent text-black border-0 font-weight-bold"
                            }
                          >
                            Search Period
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="month"
                          id="searchPeriod"
                          name="searchPeriod"
                          placeholder="searchPeriod"
                          defaultValue={searchPeriod}
                          onChange={(i) => {
                            setSearchPeriod(
                              moment(i.target.value)
                                .format("yyyy-MM")
                                .toString()
                            );
                          }}
                        />
                      </CInputGroup>
                    </div>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow alignHorizontal="center">
                  <CCol xs="12">
                    <CDataTable
                      items={invoiceList}
                      fields={[
                        { key: "delete", label: "" },
                        {
                          key: "invoiceNumber",
                          label: "#",
                          _style: { width: "1%" },
                        },
                        { key: "customerName", label: "Customer Name" },
                        { key: "customerUniqueTaxId", label: "Tax ID" },
                        { key: "invoiceDate", label: "Invoice Date" },
                        { key: "invoiceTotalNet", label: "Invoice Price" },
                        { key: "invoiceTotalTax", label: "Invoice Tax Price" },
                        { key: "invoiceTotalMoney", label: "Invoice Total" },
                      ]}
                      footer
                      underTableSlot={
                        totalNumber > 0 ? (
                          <CPagination
                            align="center"
                            activePage={activePage}
                            pages={totalNumber}
                            onActivePageChange={(i) => {
                              setActivePage(i);
                            }}
                          ></CPagination>
                        ) : (
                          <></>
                        )
                      }
                      sorter
                      overTableSlot={
                        <CRow alignHorizontal="end">
                          <CCol xs="3">
                            <CFormGroup>
                              <CInputGroup>
                                <CInputGroupPrepend>
                                  <CInputGroupText
                                    className={
                                      "bg-transparent text-black border-0 font-weight-bold"
                                    }
                                  >
                                    Items per page
                                  </CInputGroupText>
                                </CInputGroupPrepend>
                                <CSelect
                                  onChange={(i) => {
                                    setItemsPerPage(i.target.value);
                                  }}
                                  custom
                                  name="cItemsPerPage"
                                  id="cItemsPerPage"
                                  value={itemsPerPage}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="30">30</option>
                                </CSelect>
                              </CInputGroup>
                            </CFormGroup>
                          </CCol>
                        </CRow>
                      }
                      size="sm"
                      hover
                      striped
                      pages={10}
                      scopedSlots={{
                        delete: (item) => (
                          <td
                            style={{ textAlign: "center", color: "red" }}
                            onClick={(i) => onDeleteClick(item)}
                          >
                            <CIcon
                              style={{ cursor: "pointer" }}
                              name="cil-trash"
                            />
                          </td>
                        ),
                        invoiceTotalNet: (item) => {
                          return (
                            <td>
                              {parseFloat(item.invoiceTotalNet).toLocaleString(
                                "en-US",
                                { minimumFractionDigits: 3 }
                              )}
                            </td>
                          );
                        },
                        invoiceTotalTax: (item) => {
                          // console.log(item);
                          return (
                            <td>
                              {parseFloat(item.invoiceTotalTax).toLocaleString(
                                "en-US",
                                { minimumFractionDigits: 3 }
                              )}
                            </td>
                          );
                        },
                        invoiceTotalMoney: (item) => {
                          return (
                            <td>
                              {parseFloat(
                                item.invoiceTotalMoney
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 3,
                              })}
                            </td>
                          );
                        },
                      }}
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </LoadingOverlay>
    </>
  );
};

export default Vat;
