import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { ProjectsAPI } from "../../../../helpers/ApiHelper";
import ProjectInfo from "../new-project/project-info";
import ProjectStages from "../project-stages/project-stages";
import SubProjects from "../sub-projects/subprojects";

const ProjectDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    "Retrieving project details"
  );
  const [projectId] = useState(props.match.params.id);
  const [shownSection, setShownSection] = useState("projectInformation");
  const [project, setProject] = useState({
    client: undefined,
    projectName: "",
    projectDesc: "",
    startDate: undefined,
    plannedEndDate: undefined,
    actualEndDate: undefined,
    subProjects: [],
    projectStages: [],
  });
  const [validationArray, setValidationArray] = useState([]);
  const projectStatusList = useSelector((state) => {
    if (!state.globalSlicer.cache.PROJECT_STATUS) {
      return [];
    }

    const mapped = state.globalSlicer.cache.PROJECT_STATUS.map((item) => {
      return {
        key: item.lookupId,
        value: item.lookupName,
        label: item.lookupName,
        object: item,
      };
    });
    return mapped;
  });
  const projectStagesStatusList = useSelector((state) => {
    if (!state.globalSlicer.cache.PROJECT_STAGE_STATUS) {
      return [];
    }

    const mapped = state.globalSlicer.cache.PROJECT_STAGE_STATUS.map((item) => {
      return {
        key: item.lookupId,
        value: item.lookupName,
        label: item.lookupName,
        object: item,
      };
    });
    return mapped;
  });

  const loadData = () => {
    ProjectsAPI.getProject(projectId)
      .then((data) => {
        console.log(data.data);
        setProject(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMessage("Loading projects");
    let isMounted = true;
    if (isMounted) {
      loadData();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <LoadingOverlay active={loading} spinner text={loadingMessage}>
        <CRow>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>
                <CRow alignVertical="center">
                  <CCol className="font-weight-bold">Project Details</CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CCard>
                      <CCardHeader
                        onClick={() => {
                          shownSection === "projectInformation"
                            ? setShownSection("")
                            : setShownSection("projectInformation");
                        }}
                      >
                        <CRow alignVertical="center">
                          <CCol xs="11" sm="11" className="font-weight-bold">
                            Project Information
                            {shownSection === "projectInformation" ? (
                              <CIcon
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                content={freeSet.cilArrowBottom}
                              />
                            ) : (
                              <CIcon
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                name="cil-arrow-right"
                              />
                            )}
                          </CCol>
                        </CRow>
                      </CCardHeader>
                      <CCollapse show={shownSection === "projectInformation"}>
                        <ProjectInfo
                          projectItem={project}
                          validationArray={validationArray}
                          setValidationArray={setValidationArray}
                          setProjectItem={setProject}
                          projectStatusList={projectStatusList}
                        ></ProjectInfo>
                      </CCollapse>
                    </CCard>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CCard>
                      <CCardHeader
                        onClick={() => {
                          shownSection === "subProjects"
                            ? setShownSection("")
                            : setShownSection("subProjects");
                        }}
                      >
                        <CRow alignVertical="center">
                          <CCol xs="11" sm="11" className="font-weight-bold">
                            Sub-Projects
                            {shownSection === "subProjects" ? (
                              <CIcon
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                content={freeSet.cilArrowBottom}
                              />
                            ) : (
                              <CIcon
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                name="cil-arrow-right"
                              />
                            )}
                          </CCol>
                        </CRow>
                      </CCardHeader>
                      <CCollapse show={shownSection === "subProjects"}>
                        <SubProjects
                          projectItem={project}
                          setProjectItem={setProject}
                          projectStatusList={projectStatusList}
                        ></SubProjects>
                      </CCollapse>
                    </CCard>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CCard>
                      <CCardHeader
                        onClick={() => {
                          shownSection === "projectStages"
                            ? setShownSection("")
                            : setShownSection("projectStages");
                        }}
                      >
                        <CRow alignVertical="center">
                          <CCol xs="11" sm="11" className="font-weight-bold">
                            Project Stages
                            {shownSection === "projectStages" ? (
                              <CIcon
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                content={freeSet.cilArrowBottom}
                              />
                            ) : (
                              <CIcon
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                name="cil-arrow-right"
                              />
                            )}
                          </CCol>
                        </CRow>
                      </CCardHeader>
                      <CCollapse show={shownSection === "projectStages"}>
                        <ProjectStages
                          projectItem={project}
                          setProjectItem={setProject}
                          projectStagesStatusList={projectStagesStatusList}
                        ></ProjectStages>
                      </CCollapse>
                    </CCard>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </LoadingOverlay>
    </>
  );
};

export default ProjectDetail;
