import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { DatePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { EmployeesAPI } from "../../../helpers/ApiHelper";
import { isValidField, isValidForm } from "../../../helpers/ValidationHelper";

const NewEmployeeForm = (formProps) => {
  useEffect(() => { }, []);
  const [employee, setEmployee] = useState({
    employeeName: undefined,
    birthDate: undefined,
    contactNumber1: undefined,
    contactNumber2: undefined,
    address: undefined,
  });
  const [validationArray, setValidationArray] = useState([]);

  const saveEmployee = () => {
    EmployeesAPI.addEmployee(employee)
      .then(res => {
        formProps.setReloadData(true);
        formProps.showEntryModal(false);
      });

  };

  return (
    <>
      {/* <LoadingOverlay active={loading} spinner text={loadingMessage}> */}
      <CRow>
        <CCol xs="12" sm="12">
          <CCard>
            <CCardBody>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Employee Name
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="employeeName"
                      name="employeeName"
                      autoComplete="employeeName"
                      placeholder="Enter Employee's name"
                      value={employee.employeeName}
                      invalid={validationArray?.indexOf("employeeName") >= 0}
                      onChange={(val) => {
                        setEmployee((oldVal) => {
                          return { ...oldVal, employeeName: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Contact Number 1
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="contactNumber1"
                      name="contactNumber1"
                      autoComplete="contactNumber1"
                      placeholder="Enter Contact number"
                      value={employee.contactNumber1}
                      invalid={validationArray?.indexOf("contactNumber1") >= 0}
                      onChange={(val) => {
                        setEmployee((oldVal) => {
                          return {
                            ...oldVal,
                            contactNumber1: val.target.value,
                          };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Contact Number 2
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="contactNumber2"
                      name="contactNumber2"
                      autoComplete="contactNumber2"
                      placeholder="Enter Contact number"
                      value={employee.contactNumber2}
                      invalid={validationArray?.indexOf("contactNumber2") >= 0}
                      onChange={(val) => {
                        setEmployee((oldVal) => {
                          return {
                            ...oldVal,
                            contactNumber2: val.target.value,
                          };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Address
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="address"
                      name="address"
                      autoComplete="address"
                      placeholder="Enter Client's address"
                      value={employee.address}
                      invalid={validationArray?.indexOf("address") >= 0}
                      onChange={(val) => {
                        setEmployee((oldVal) => {
                          return { ...oldVal, address: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                // alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Birth date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                            validationArray?.indexOf("birthDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={employee?.birthDate}
                      onChange={(date) => {
                        if (date) {
                          setEmployee((oldVal) => {
                            return {
                              ...oldVal,
                              birthDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setEmployee((oldVal) => {
                            return { ...oldVal, birthDate: undefined };
                          });
                        }
                        isValidField(
                          "birthDate",
                          date,
                          validationArray,
                          setValidationArray
                        );
                      }}
                      disabledDate={(current) => current && current > moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="3">
                  <CButton
                    block
                    shape="pill"
                    variant="outline"
                    color="primary"
                    className=""
                    onClick={() => {
                      const valid = isValidForm(
                        Object.fromEntries(
                          Object.entries(employee).filter(
                            (item) => item[0] !== "birthDate"
                          )
                        ),
                        setValidationArray
                      );
                      // console.log(valid);
                      if (valid) {
                        // console.log("saving", client);
                        saveEmployee();
                      } else {
                        // console.log("not valid", validationArray);
                      }
                    }}
                  >
                    Save
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/* </LoadingOverlay> */}
    </>
  );
};

export default NewEmployeeForm;
