import {
  CCard,
  CCardBody,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CTextarea,
} from "@coreui/react";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import {
  isValidField,
  areValidDates,
} from "../../../../helpers/ValidationHelper";

const ProjectInfo = (props) => {
  //   console.log("project info props:", props.clients);
  const [clientsList, setClientsList] = useState(props.clients);
  const [projectStatusList, setProjectStatusList] = useState(
    props.projectStatusList
  );

  useEffect(() => {
    // console.log("project info useEffect:", props.clients, clientsList);
    setClientsList(props.clients);
    setProjectStatusList(props.projectStatusList);
  }, [props.clients, props.projectStatusList]);
  return (
    <>
      <CRow>
        <CCol xs="12" sm="12">
          <CCard>
            {props.stepHeader ? props.stepHeader(props) : <></>}
            <CCardBody>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Client
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    {props.clients ? (
                      <>
                        <div
                          className="form-control"
                          style={{
                            padding: "0 0 0 0",
                            height: "fit-content",
                            borderWidth:
                              props.validationArray &&
                              props.validationArray?.indexOf("client") >= 0
                                ? 1
                                : 0,
                            borderColor:
                              props.validationArray &&
                              props.validationArray?.indexOf("client") >= 0
                                ? "red"
                                : "rgb(216, 219, 224)",
                          }}
                        >
                          <Select
                            id="clientList"
                            showSearch={true}
                            placeholder="Select Client"
                            isClearable
                            isSearchable
                            value={props.projectItem?.client}
                            onChange={(val) => {
                              let fieldValue = val ? val.object : val;
                              props.setProjectItem((oldVal) => {
                                return { ...oldVal, client: val };
                              });
                              isValidField(
                                "client",
                                fieldValue,
                                props.validationArray,
                                props.setValidationArray
                              );
                            }}
                            options={clientsList}
                          />
                        </div>
                        <CInputGroupAppend>
                          <CInputGroupText
                            className={"bg-white text-danger border-0"}
                          >
                            *
                          </CInputGroupText>
                        </CInputGroupAppend>
                      </>
                    ) : (
                      <CInput
                        type="text"
                        id="clientName"
                        name="clientName"
                        autoComplete="clientName"
                        value={
                          props.projectItem?.client?.englishName +
                          " - " +
                          props.projectItem?.client?.arabicName
                        }
                        disabled
                      />
                    )}
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Project name
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="projectName"
                      name="projectName"
                      autoComplete="projectName"
                      placeholder="Enter Project name"
                      value={props.projectItem?.projectName}
                      invalid={
                        props.validationArray?.indexOf("projectName") >= 0
                      }
                      onChange={(val) => {
                        props.setProjectItem((oldVal) => {
                          return { ...oldVal, projectName: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          props.validationArray,
                          props.setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Project desc
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CTextarea
                      type="text"
                      id="projectDesc"
                      name="projectDesc"
                      autoComplete="projectDesc"
                      placeholder="Enter a brief project description"
                      value={props.projectItem?.projectDesc}
                      onChange={(val) => {
                        props.setProjectItem((oldVal) => {
                          return { ...oldVal, projectDesc: val.target.value };
                        });
                      }}
                    />
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Project status
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <div
                      className="form-control"
                      style={{
                        padding: "0 0 0 0",
                        height: "fit-content",
                        borderWidth: 0,
                      }}
                    >
                      <Select
                        id="projectStatus"
                        showSearch={true}
                        placeholder="Select status"
                        isSearchable
                        value={
                          props.projectItem?.projectStatus
                            ? props.projectItem?.projectStatus
                            : projectStatusList[0]
                        }
                        defaultValue={projectStatusList[0]}
                        onChange={(val) => {
                          props.setProjectItem((oldVal) => {
                            return { ...oldVal, projectStatus: val };
                          });
                        }}
                        options={projectStatusList}
                      />
                    </div>
                  </CInputGroup>
                </CCol>
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Start date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          props.validationArray &&
                          props.validationArray?.indexOf("plannedStartDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={props.projectItem?.plannedStartDate}
                      onChange={(date) => {
                        if (date) {
                          props.setProjectItem((oldVal) => {
                            return {
                              ...oldVal,
                              plannedStartDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          props.setProjectItem((oldVal) => {
                            return { ...oldVal, plannedStartDate: undefined };
                          });
                        }
                        isValidField(
                          "plannedStartDate",
                          date,
                          props.validationArray,
                          props.setValidationArray
                        );
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"MM/DD/YYYY"}
                    />
                    {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                  </CInputGroup>
                </CCol>
              </CRow>
              {/* <CRow alignHorizontal="left" style={{ paddingBottom: "inherit" }}> */}
                {/* <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Planned end date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          props.validationArray &&
                          props.validationArray?.indexOf("plannedEndDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={props.projectItem?.plannedEndDate}
                      onChange={(date) => {
                        if (date) {
                          props.setProjectItem((oldVal) => {
                            return {
                              ...oldVal,
                              plannedEndDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          props.setProjectItem((oldVal) => {
                            return { ...oldVal, plannedEndDate: undefined };
                          });
                        }
                        areValidDates(
                          "plannedEndDate",
                          props.projectItem?.startDate,
                          date ? moment(date).format("DD/MM/YYYY") : undefined,
                          props.validationArray,
                          props.setValidationArray
                        );
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"MM/DD/YYYY"}
                    /> */}
                    {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                  {/* </CInputGroup> */}
                  {/* {props.projectItem?.plannedEndDate &&
                  props.validationArray &&
                  props.validationArray?.indexOf("plannedEndDate") >= 0 ? (
                    <font style={{ color: "red", paddingRight: 10 }}>
                      End date must be after start date
                    </font>
                  ) : (
                    <></>
                  )} */}
                {/* </CCol> */}
                {/* <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Actual end date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          props.validationArray &&
                          props.validationArray?.indexOf("actualEndDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={props.projectItem?.actualEndDate}
                      onChange={(date) => {
                        if (date) {
                          props.setProjectItem((oldVal) => {
                            return {
                              ...oldVal,
                              actualEndDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                          areValidDates(
                            "actualEndDate",
                            props.projectItem?.startDate,
                            date
                              ? moment(date).format("DD/MM/YYYY")
                              : undefined,
                            props.validationArray,
                            props.setValidationArray
                          );
                        } else {
                          props.setProjectItem((oldVal) => {
                            return { ...oldVal, actualEndDate: undefined };
                          });
                        }
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </CInputGroup>
                  {props.projectItem?.actualEndDate &&
                  props.validationArray &&
                  props.validationArray?.indexOf("actualEndDate") >= 0 ? (
                    <font style={{ color: "red", paddingRight: 10 }}>
                      End date must be after start date
                    </font>
                  ) : (
                    <></>
                  )}
                </CCol> */}
              {/* </CRow> */}
            </CCardBody>
            {props.stepFooter ? props.stepFooter(props) : <></>}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ProjectInfo;
