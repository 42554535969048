import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { AuthenticationAPI } from "../../../helpers/ApiHelper";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import { updateFilteredNavigations, updateIsLoggedIn } from "../../../redux/slicers/GlobalSlicer";
import { getAvailableNavs } from "../../../helpers/Utils";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Signing in");
  const [loginFailed, setLoginFailed] = useState(false);
  const dispatch = useDispatch();

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onLoginButtonClick();
    }
  };

  const onLoginButtonClick = (event) => {
    setLoadingMessage("Signing in");
    setLoading(true);
    const authorizationString = "Basic " + btoa(username + ":" + password);
    // console.log(authorizationString);
    AuthenticationAPI.login(
      authorizationString
    ).then((resp) => {
      // console.log(resp);
      if (resp.status === 200) {
        // console.log(resp.data);
        localStorage.setItem("AUTHORIZATION", resp.data.token);
        localStorage.setItem("USERNAME", username);
        const filteredNavs = getAvailableNavs(resp.data.modulesList);
        localStorage.setItem("AVAILABLE_NAVS", JSON.stringify(filteredNavs))
        // console.log('modulesList',resp.data.modulesList);
        dispatch(updateFilteredNavigations(filteredNavs));
        dispatch(updateIsLoggedIn(true));
      }
      setLoading(false);
    })
      .catch((error) => {
        setLoginFailed(true);
        setLoading(false);
      });
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow alignHorizontal="center">
          <CCol xs="12" style={{ textAlign: "center" }}>
            {/* <CCard> */}
            <img alt="" style={{ maxWidth: 200 }} src={window.location.origin + "/logo.png"} className="c-sidebar-brand-full"></img>
            {/* </CCard> */}
          </CCol>
        </CRow>
        <CRow alignHorizontal="center">
          <CCol xs="12">
            <h1 style={{ textAlign: "center" }}>Welcome to AEC Portal</h1>
          </CCol>
        </CRow>

        <CRow className="justify-content-center">
          <CCol md="8">
            <LoadingOverlay active={loading} spinner text={loadingMessage}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={onLoginButtonClick}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="text"
                          required
                          invalid={loginFailed}
                          placeholder="Username"
                          autoComplete="username"
                          value={username}
                          onKeyDown={onKeyDown}
                          onChange={(item) => {
                            setUsername(item.target.value);
                          }}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="password"
                          required
                          invalid={loginFailed}
                          placeholder="Password"
                          autoComplete="current-password"
                          value={password}
                          onKeyDown={onKeyDown}
                          onChange={(item) => {
                            setPassword(item.target.value);
                          }}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4 text-danger">
                        {loginFailed
                          ? "Wrong username or password, please double check them"
                          : ""}
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <CButton
                            type="submit"
                            color="primary"
                            className="px-4"
                            // onClick={onLoginButtonClick}
                          >
                            Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </LoadingOverlay>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
