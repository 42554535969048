import moment from "moment";

export const areValidDates = (
  fieldName,
  startValue,
  endValue,
  validationArray,
  setValidationArray
) => {
  let valid = true;
  const exists = validationArray.includes(fieldName);
  const startDate = moment(startValue, "DD/MM/YYYY");
  const endDate = moment(endValue, "DD/MM/YYYY");
  if ((!startValue || !endValue) && startDate >= endDate) {
    valid = false;
  }
  if (valid && exists) {
    validationArray.splice(validationArray.indexOf(fieldName), 1);
  } else if (!valid && !exists) {
    setValidationArray((oldVal) => [...oldVal, fieldName]);
  }
  return valid;
};

export const isValidField = (
  fieldName,
  fieldValue,
  validationArray,
  setValidationArray
) => {
  let valid = true;
  const exists = validationArray.includes(fieldName);
  if (!fieldValue || fieldValue.length === 0) {
    valid = false;
  }
  if (valid && exists) {
    validationArray.splice(validationArray.indexOf(fieldName), 1);
  } else if (!valid && !exists && setValidationArray) {
    setValidationArray((oldVal) => [...oldVal, fieldName]);
  }
  // console.log(fieldName, fieldValue, validationArray);
  return valid;
};

export const isValidForm = (form, callback) => {
  let invalidItems = [];
  // console.log('hereee:',form);
  Object.entries(form).forEach((item) => {
    // console.log('hereeeeeeeeeee:',item);
    if (!isValidField(item[0], item[1], invalidItems, undefined)) {
      // console.log("invalid field");
      invalidItems = [...invalidItems, item[0]];
    }
  });
  if (invalidItems.length > 0) {
    if (callback) callback(invalidItems);
    return false;
  }
  return true;
};
