import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { useState } from "react";
import {
  areValidDates,
  isValidField,
  isValidForm,
} from "../../../../helpers/ValidationHelper";

const SubProjectDetails = (props) => {
  const DEFAULT_SUBPROJECT = {
    subProjectName: "",
    plannedStartDate: undefined,
    plannedEndDate: undefined,
    actualStartDate: undefined,
    actualEndDate: undefined,
  };
  const [subProject, setSubProject] = useState(DEFAULT_SUBPROJECT);
  const [validationArray, setValidationArray] = useState([]);

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12">
          <CCard>
            {props.stepHeader ? props.stepHeader(props) : <></>}
            <CCardBody>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Sub-Project name
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="subProjectName"
                      name="subProjectName"
                      autoComplete="subProjectName"
                      placeholder="Enter sub-project name"
                      value={subProject.subProjectName}
                      invalid={validationArray.indexOf("subProjectName") >= 0}
                      onChange={(val) => {
                        setSubProject((oldVal) => {
                          return {
                            ...oldVal,
                            subProjectName: val.target.value,
                          };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Planned start date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                          validationArray.indexOf("plannedStartDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={subProject.plannedStartDate}
                      onChange={(date) => {
                        if (date) {
                          setSubProject((oldVal) => {
                            return {
                              ...oldVal,
                              plannedStartDate:
                                moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setSubProject((oldVal) => {
                            return { ...oldVal, plannedStartDate: undefined };
                          });
                        }
                        isValidField(
                          "plannedStartDate",
                          date,
                          validationArray,
                          setValidationArray
                        );
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                    {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                  </CInputGroup>
                </CCol>
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Planned end date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                          validationArray.indexOf("plannedEndDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={subProject.plannedEndDate}
                      onChange={(date) => {
                        if (date) {
                          setSubProject((oldVal) => {
                            return {
                              ...oldVal,
                              plannedEndDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setSubProject((oldVal) => {
                            return { ...oldVal, plannedEndDate: undefined };
                          });
                        }
                        areValidDates(
                          "plannedEndDate",
                          subProject.plannedStartDate,
                          date ? moment(date).format("DD/MM/YYYY") : undefined,
                          validationArray,
                          setValidationArray
                        );
                        // validateDate(
                        //   "plannedEndDate",
                        //   subProject.plannedStartDate,
                        //   date ? moment(date).format("DD/MM/YYYY") : undefined
                        // );
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                    {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                  </CInputGroup>
                  {subProject.plannedEndDate &&
                  validationArray &&
                  validationArray.indexOf("plannedEndDate") >= 0 ? (
                    <font style={{ color: "red", paddingRight: 10 }}>
                      End date must be after start date
                    </font>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Actual start date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor: "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={subProject.actualStartDate}
                      onChange={(date) => {
                        if (date) {
                          setSubProject((oldVal) => {
                            return {
                              ...oldVal,
                              actualStartDate:
                                moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setSubProject((oldVal) => {
                            return { ...oldVal, actualStartDate: undefined };
                          });
                        }
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                  </CInputGroup>
                </CCol>
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Actual end date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                          validationArray.indexOf("actualEndDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={subProject.actualEndDate}
                      onChange={(date) => {
                        if (date) {
                          setSubProject((oldVal) => {
                            return {
                              ...oldVal,
                              actualEndDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                          areValidDates(
                            "actualEndDate",
                            subProject.plannedStartDate,
                            date
                              ? moment(date).format("DD/MM/YYYY")
                              : undefined,
                            validationArray,
                            setValidationArray
                          );
                          // validateDate(
                          //   "actualEndDate",
                          //   subProject.actualStartDate,
                          //   date ? moment(date).format("DD/MM/YYYY") : undefined
                          // );
                        } else {
                          setSubProject((oldVal) => {
                            return { ...oldVal, actualEndDate: undefined };
                          });
                        }
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                  </CInputGroup>
                  {subProject.actualEndDate &&
                  validationArray &&
                  validationArray.indexOf("actualEndDate") >= 0 ? (
                    <font style={{ color: "red", paddingRight: 10 }}>
                      End date must be after start date
                    </font>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
              <CRow alignHorizontal="left" style={{ paddingBottom: "inherit" }}>
                <CCol xs="3">
                  <CButton
                    block
                    shape="pill"
                    color="primary"
                    variant="outline"
                    className=""
                    onClick={() => {
                      if (
                        isValidForm(
                          Object.fromEntries(
                            Object.entries(subProject).filter(
                              (item) =>
                                item[0] !== "plannedStartDate" &&
                                item[0] !== "plannedEndDate" &&
                                item[0] !== "actualStartDate" &&
                                item[0] !== "actualEndDate"
                            )
                          ),
                          setValidationArray
                        )
                      ) {
                        // if (validate(subProject)) {
                        props.setProjectItem((oldValue) => {
                          const oldSubProjects = oldValue.subProjects
                            ? oldValue.subProjects
                            : [];
                          props.showEntryModal(false);
                          return {
                            ...oldValue,
                            subProjects: [...oldSubProjects, subProject],
                          };
                        });
                        setSubProject(DEFAULT_SUBPROJECT);
                      }
                    }}
                  >
                    Add sub-project
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
            {props.stepFooter ? props.stepFooter(props) : <></>}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default SubProjectDetails;
