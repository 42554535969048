/* tslint:disable */
/* eslint-disable */
/**
 * AEC Portal
 * This is the api for the AEC Portal.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mabdousaad@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessToken
     */
    expiresIn?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    type?: string;
    /**
     * 
     * @type {Array<UserModulesModel>}
     * @memberof AccessToken
     */
    modulesList?: Array<UserModulesModel>;
}
/**
 * 
 * @export
 * @interface ApiErrorModel
 */
export interface ApiErrorModel {
    /**
     * 
     * @type {number}
     * @memberof ApiErrorModel
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiErrorModel
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorModel
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorModel
     */
    stacktrace?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorModel
     */
    help?: string;
}
/**
 * 
 * @export
 * @interface Attendee
 */
export interface Attendee {
    /**
     * 
     * @type {string}
     * @memberof Attendee
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Attendee
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
    /**
     * 
     * @type {number}
     * @memberof BaseResponse
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseResponse
     */
    statusDescription?: string;
}
/**
 * 
 * @export
 * @interface CertificatesRequest
 */
export interface CertificatesRequest {
    /**
     * 
     * @type {Array<Attendee>}
     * @memberof CertificatesRequest
     */
    attendees?: Array<Attendee>;
    /**
     * 
     * @type {string}
     * @memberof CertificatesRequest
     */
    courseName?: string;
    /**
     * 
     * @type {Array<StandardModel>}
     * @memberof CertificatesRequest
     */
    standards?: Array<StandardModel>;
    /**
     * 
     * @type {string}
     * @memberof CertificatesRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificatesRequest
     */
    toDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CertificatesRequest
     */
    isArabic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertificatesRequest
     */
    isOneDay?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertificatesRequest
     */
    isAttend?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertificatesRequest
     */
    courseLocation?: string;
}
/**
 * 
 * @export
 * @interface ClientModel
 */
export interface ClientModel {
    /**
     * 
     * @type {number}
     * @memberof ClientModel
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientModel
     */
    arabicName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientModel
     */
    englishName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientModel
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientModel
     */
    taxId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientModel
     */
    projectCount?: number;
    /**
     * 
     * @type {Array<ProjectModel>}
     * @memberof ClientModel
     */
    projects?: Array<ProjectModel>;
}
/**
 * 
 * @export
 * @interface DocumentTax
 */
export interface DocumentTax {
    /**
     * 
     * @type {Array<object>}
     * @memberof DocumentTax
     */
    lstTaxpayerData?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    taxPeriod?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    taxpayerID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    fromDate?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    declarationID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    uniqueTaxID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    taxPeriodDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    updatedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    sendDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTax
     */
    replicated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    paidDate?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    vatTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    invoiceTaxCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    invoiceTaxFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    invoiceTaxTo?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    noticesAddCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    noticesAddFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    noticesAddTo?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    noticesDiscountCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    noticesDiscountFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    noticesDiscountTo?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    otherCount?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    otherFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    purchaseInvoiceCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    authorizationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    totalAnnualIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    totalAnnualPurchase?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    toDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTax
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTax
     */
    send?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTax
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTax
     */
    otherTo?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTax
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface ExtractDataModel
 */
export interface ExtractDataModel {
    /**
     * 
     * @type {Array<PurchasesTableDetail>}
     * @memberof ExtractDataModel
     */
    lstPurchasesTable?: Array<PurchasesTableDetail>;
    /**
     * 
     * @type {Array<SalesTableDetail>}
     * @memberof ExtractDataModel
     */
    lstSalesTable?: Array<SalesTableDetail>;
    /**
     * 
     * @type {Array<TaxTableDetail>}
     * @memberof ExtractDataModel
     */
    lstTaxTable?: Array<TaxTableDetail>;
    /**
     * 
     * @type {Array<MergeAllTaxTable>}
     * @memberof ExtractDataModel
     */
    lstMergeAllTaxTable?: Array<MergeAllTaxTable>;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    getoTaxpayerDataVm?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExtractDataModel
     */
    lstTaxpayerData?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExtractDataModel
     */
    lstSalesInvoices?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExtractDataModel
     */
    lstPurchaseInvoices?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    countWithNoPaidDeclaration?: number;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    taxPeriod?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    applicantName?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    nationalPassNum?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    authorizationNumber?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    representitive?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    signedDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    taxpayerID?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    salesTableDetails?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    tableTaxDetails?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    purchaseTableDetails?: object;
    /**
     * 
     * @type {string}
     * @memberof ExtractDataModel
     */
    fromDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtractDataModel
     */
    paidTable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    declarationTypeID?: number;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    declarationTypeName?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    documentNum?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    linkCodeID?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    uniqueTaxID?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    fromTaxPeriod?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    toTaxPeriod?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    taxPeriodDate?: object;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    tableTaxTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    tableTaxAdjustment?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    tableTaxVat?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    salesTableTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    salesTableAdjustment?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    salesTableVat?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    purchaseTableTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    purchaseTableAdjustment?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    purchaseTableVat?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    recivableTableTax?: number;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    inquiryTransAmountTVAT?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    inquiryTransAmountVAT?: object;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    debtTableValue?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    totalTableTaxValue?: number;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    remainingPayment?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    remainingPaymentTable?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    personalizeTypeID?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    creationDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    updatedDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    sendDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    fromSendDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    toSendDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    paidDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    paymentStatus?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    sendStatus?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    taxpayerName?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    paymentMethod?: object;
    /**
     * 
     * @type {string}
     * @memberof ExtractDataModel
     */
    dateFrom?: string;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    districtID?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    districtName?: object;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    countDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    countTempDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    countSavedDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    countPaidDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    countNonPaidDeclaration?: number;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    reconciliationType?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    recTypeFrom?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    recTypeTo?: object;
    /**
     * 
     * @type {string}
     * @memberof ExtractDataModel
     */
    acceptanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtractDataModel
     */
    rejectionDate?: string;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    recoPaymentDate?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    reconciliationValue?: object;
    /**
     * 
     * @type {string}
     * @memberof ExtractDataModel
     */
    extractedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ExtractDataModel
     */
    tableVatID?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtractDataModel
     */
    toDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtractDataModel
     */
    signed?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    dateTo?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    isSend?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    isPaid?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    salesInvoices?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    purchaseInvoices?: object;
    /**
     * 
     * @type {DocumentTax}
     * @memberof ExtractDataModel
     */
    documentTax?: DocumentTax;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    isDeleted?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtractDataModel
     */
    isReplicated?: object;
    /**
     * 
     * @type {boolean}
     * @memberof ExtractDataModel
     */
    extracted?: boolean;
}
/**
 * 
 * @export
 * @interface FatoraDetails
 */
export interface FatoraDetails {
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    invoiceTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    itemTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    sectionNumID?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    customerFileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    customerNationalId?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    customerMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    productTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    taxTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    productTypeDescr?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    productUnit?: string;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    productUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    productQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    productTotalNet?: number;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    productTotalTaxValue?: number;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    productTotalMoney?: number;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    customerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    customerUniqueTaxId?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FatoraDetails
     */
    taxType?: string;
    /**
     * 
     * @type {number}
     * @memberof FatoraDetails
     */
    tax?: number;
}
/**
 * 
 * @export
 * @interface Form10InputData
 */
export interface Form10InputData {
    /**
     * 
     * @type {string}
     * @memberof Form10InputData
     */
    applicantName?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10InputData
     */
    nationalPassNum?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10InputData
     */
    authorizationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10InputData
     */
    representitive?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10InputData
     */
    signedDate?: string;
}
/**
 * 
 * @export
 * @interface Form10Model
 */
export interface Form10Model {
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    fromSendDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    toSendDate?: string;
    /**
     * 
     * @type {Array<PurchaseTableDetail>}
     * @memberof Form10Model
     */
    purchaseTableDetailsList?: Array<PurchaseTableDetail>;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    countWithNoPaidDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    taxPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    applicantName?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    nationalPassNum?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    authorizationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    representitive?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    signedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    taxpayerID?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    salesTableDetails?: string;
    /**
     * 
     * @type {Array<SalesTableDetail>}
     * @memberof Form10Model
     */
    salesTableDetailsList?: Array<SalesTableDetail>;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    tableTaxDetails?: string;
    /**
     * 
     * @type {Array<TableTaxDetail>}
     * @memberof Form10Model
     */
    tableTaxDetailsList?: Array<TableTaxDetail>;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    purchaseTableDetails?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Form10Model
     */
    paidTable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    tableVATID?: string;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    declarationTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    declarationTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    documentNum?: string;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    linkCodeID?: number;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    uniqueTaxID?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    fromTaxPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    toTaxPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    taxPeriodDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    tableTaxTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    tableTaxAdjustment?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    tableTaxVat?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    salesTableTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    salesTableAdjustment?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    salesTableVat?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    purchaseTableTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    purchaseTableAdjustment?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    purchaseTableVat?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    recivableTableTax?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    inquiryTransAmountTVAT?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    inquiryTransAmountVAT?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    debtTableValue?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    totalTableTaxValue?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    remainingPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    remainingPaymentTable?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    personalizeTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    updatedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    sendDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Form10Model
     */
    replicated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    paidDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    paymentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    sendStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    taxpayerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    dateFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    districtID?: number;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    districtName?: string;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    countDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    countTempDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    countSavedDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    countPaidDeclaration?: number;
    /**
     * 
     * @type {number}
     * @memberof Form10Model
     */
    countNonPaidDeclaration?: number;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    reconciliationType?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    recTypeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    recTypeTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    acceptanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    rejectionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    recoPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    reconciliationValue?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    isExtracted?: string;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    extractedDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Form10Model
     */
    signed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Form10Model
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Form10Model
     */
    send?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Form10Model
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Form10Model
     */
    dateTo?: string;
}
/**
 * 
 * @export
 * @interface ListForm10Response
 */
export interface ListForm10Response {
    /**
     * 
     * @type {string}
     * @memberof ListForm10Response
     */
    aggregateResults?: string;
    /**
     * 
     * @type {Array<Form10Model>}
     * @memberof ListForm10Response
     */
    data?: Array<Form10Model>;
    /**
     * 
     * @type {number}
     * @memberof ListForm10Response
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof ListForm10Response
     */
    errors?: string;
}
/**
 * 
 * @export
 * @interface ListInvoiceData
 */
export interface ListInvoiceData {
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    itemTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    sectionNumID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerNationalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    taxTypeID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productTypeDescr?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productUnitPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productQuantity?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    discount?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productTotalNet?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productTotalTaxValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productTotalMoney?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerUniqueTaxId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    salesInvoice_Id?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    purchaseInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceType?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    itemType?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    sectionNum?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceDateStr?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceTotalNet?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceTotalTax?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    invoiceTotalMoney?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerFileNumb?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    customerForgeinId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    productTaxValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    isVatFileType?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    taxpayerID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    totalTaxContainer?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    taxType?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    tax?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceData
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface ListInvoiceResponse
 */
export interface ListInvoiceResponse {
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceResponse
     */
    aggregateResults?: string;
    /**
     * 
     * @type {Array<ListInvoiceData>}
     * @memberof ListInvoiceResponse
     */
    data?: Array<ListInvoiceData>;
    /**
     * 
     * @type {number}
     * @memberof ListInvoiceResponse
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvoiceResponse
     */
    errors?: string;
}
/**
 * 
 * @export
 * @interface LookupModel
 */
export interface LookupModel {
    /**
     * 
     * @type {number}
     * @memberof LookupModel
     */
    lookupId?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupModel
     */
    lookupName?: string;
}
/**
 * 
 * @export
 * @interface MergeAllTaxTable
 */
export interface MergeAllTaxTable {
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    itemValue?: number;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    serviceValue?: number;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    totalValue?: number;
    /**
     * 
     * @type {string}
     * @memberof MergeAllTaxTable
     */
    taxTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    taxTypeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    tableVatID?: number;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof MergeAllTaxTable
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface NewEmployeeRequest
 */
export interface NewEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof NewEmployeeRequest
     */
    employeeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmployeeRequest
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmployeeRequest
     */
    contactNumber1?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmployeeRequest
     */
    contactNumber2?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmployeeRequest
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface PaginatedClientsRequest
 */
export interface PaginatedClientsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedClientsRequest
     */
    sortingArray?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PaginatedClientsRequest
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedClientsRequest
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedClientsRequest
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedClientsRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedClientsRequest
     */
    filterQuery?: string;
}
/**
 * 
 * @export
 * @interface PaginatedEmployeesRequest
 */
export interface PaginatedEmployeesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedEmployeesRequest
     */
    sortingArray?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedEmployeesRequest
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedEmployeesRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedEmployeesRequest
     */
    filterQuery?: string;
}
/**
 * 
 * @export
 * @interface PaginatedProjectsRequest
 */
export interface PaginatedProjectsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedProjectsRequest
     */
    sortingArray?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectsRequest
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectsRequest
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectsRequest
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectsRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectsRequest
     */
    filterQuery?: string;
}
/**
 * 
 * @export
 * @interface ProjectModel
 */
export interface ProjectModel {
    /**
     * 
     * @type {number}
     * @memberof ProjectModel
     */
    projectId?: number;
    /**
     * 
     * @type {ClientModel}
     * @memberof ProjectModel
     */
    client?: ClientModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    projectDesc?: string;
    /**
     * 
     * @type {LookupModel}
     * @memberof ProjectModel
     */
    projectStatus?: LookupModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    plannedEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    actualEndDate?: string;
    /**
     * 
     * @type {Array<SubProjectModel>}
     * @memberof ProjectModel
     */
    subProjects?: Array<SubProjectModel>;
    /**
     * 
     * @type {Array<ProjectStageModel>}
     * @memberof ProjectModel
     */
    projectStages?: Array<ProjectStageModel>;
}
/**
 * 
 * @export
 * @interface ProjectStageModel
 */
export interface ProjectStageModel {
    /**
     * 
     * @type {number}
     * @memberof ProjectStageModel
     */
    stageId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectStageModel
     */
    stageDesc?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectStageModel
     */
    projectId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectStageModel
     */
    plannedStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStageModel
     */
    actualStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStageModel
     */
    plannedEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStageModel
     */
    actualEndDate?: string;
    /**
     * 
     * @type {LookupModel}
     * @memberof ProjectStageModel
     */
    stageStatus?: LookupModel;
}
/**
 * 
 * @export
 * @interface PurchaseTableDetail
 */
export interface PurchaseTableDetail {
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    totalValue?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    exported?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseTableDetail
     */
    taxTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    taxTypeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    tableVatID?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseTableDetail
     */
    local?: number;
}
/**
 * 
 * @export
 * @interface PurchasesTableDetail
 */
export interface PurchasesTableDetail {
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    purchaseTypeValue?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasesTableDetail
     */
    purchaseTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    totalValue?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    exported?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    tableVatID?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    local?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasesTableDetail
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface SalesTableDetail
 */
export interface SalesTableDetail {
    /**
     * 
     * @type {number}
     * @memberof SalesTableDetail
     */
    taxContainer?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesTableDetail
     */
    taxTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesTableDetail
     */
    taxTypeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesTableDetail
     */
    tableVatID?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesTableDetail
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesTableDetail
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface StandardModel
 */
export interface StandardModel {
    /**
     * 
     * @type {number}
     * @memberof StandardModel
     */
    standardId?: number;
    /**
     * 
     * @type {string}
     * @memberof StandardModel
     */
    standardName?: string;
    /**
     * 
     * @type {string}
     * @memberof StandardModel
     */
    englishText?: string;
    /**
     * 
     * @type {string}
     * @memberof StandardModel
     */
    arabicText?: string;
}
/**
 * 
 * @export
 * @interface SubProjectModel
 */
export interface SubProjectModel {
    /**
     * 
     * @type {number}
     * @memberof SubProjectModel
     */
    subProjectId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubProjectModel
     */
    projectId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubProjectModel
     */
    subProjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubProjectModel
     */
    plannedStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubProjectModel
     */
    actualStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubProjectModel
     */
    plannedEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubProjectModel
     */
    actualEndDate?: string;
}
/**
 * 
 * @export
 * @interface TableTaxDetail
 */
export interface TableTaxDetail {
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    itemValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    serviceValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    totalValue?: number;
    /**
     * 
     * @type {string}
     * @memberof TableTaxDetail
     */
    taxTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    taxTypeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    tableVatID?: number;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TableTaxDetail
     */
    tax?: number;
}
/**
 * 
 * @export
 * @interface TaxTableDetail
 */
export interface TaxTableDetail {
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    itemValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    serviceValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    totalValue?: number;
    /**
     * 
     * @type {string}
     * @memberof TaxTableDetail
     */
    taxTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    taxTypeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    tableVatID?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxTableDetail
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface UserModulesModel
 */
export interface UserModulesModel {
    /**
     * 
     * @type {number}
     * @memberof UserModulesModel
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModulesModel
     */
    moduleURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModulesModel
     */
    backend?: boolean;
}
/**
 * 
 * @export
 * @interface VATLoginResponse
 */
export interface VATLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof VATLoginResponse
     */
    verificationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof VATLoginResponse
     */
    responseBody?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VATLoginResponse
     */
    captchaImage?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VATLoginResponse
     */
    encodedCaptchaImage?: string;
}
/**
 * 
 * @export
 * @interface VatExtractResponse
 */
export interface VatExtractResponse {
    /**
     * 
     * @type {number}
     * @memberof VatExtractResponse
     */
    extractResult?: number;
    /**
     * 
     * @type {string}
     * @memberof VatExtractResponse
     */
    message?: string;
    /**
     * 
     * @type {ExtractDataModel}
     * @memberof VatExtractResponse
     */
    data?: ExtractDataModel;
    /**
     * 
     * @type {boolean}
     * @memberof VatExtractResponse
     */
    exist?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VatExtractResponse
     */
    done?: boolean;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary check if user is logged in
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isLoggedIn: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('isLoggedIn', 'authorization', authorization)
            const localVarPath = `/auth/isLoggedIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the authentication token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('login', 'authorization', authorization)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invalidate token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('logout', 'authorization', authorization)
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh the authentication token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('refresh', 'authorization', authorization)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary check if user is logged in
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isLoggedIn(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isLoggedIn(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the authentication token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary invalidate token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh the authentication token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary check if user is logged in
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isLoggedIn(authorization: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.isLoggedIn(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the authentication token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(authorization: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.login(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invalidate token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(authorization: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.logout(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh the authentication token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(authorization: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.refresh(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary check if user is logged in
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public isLoggedIn(authorization: string, options?: any) {
        return AuthenticationApiFp(this.configuration).isLoggedIn(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the authentication token
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(authorization: string, options?: any) {
        return AuthenticationApiFp(this.configuration).login(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invalidate token
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(authorization: string, options?: any) {
        return AuthenticationApiFp(this.configuration).logout(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh the authentication token
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refresh(authorization: string, options?: any) {
        return AuthenticationApiFp(this.configuration).refresh(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CacheApi - axios parameter creator
 * @export
 */
export const CacheApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get lookups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CacheApi - functional programming interface
 * @export
 */
export const CacheApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CacheApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get lookups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLookups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CacheApi - factory interface
 * @export
 */
export const CacheApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CacheApiFp(configuration)
    return {
        /**
         * 
         * @summary get lookups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookups(options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.getLookups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CacheApi - object-oriented interface
 * @export
 * @class CacheApi
 * @extends {BaseAPI}
 */
export class CacheApi extends BaseAPI {
    /**
     * 
     * @summary get lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApi
     */
    public getLookups(options?: any) {
        return CacheApiFp(this.configuration).getLookups(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CertificatesApi - axios parameter creator
 * @export
 */
export const CertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary generate certificates
         * @param {CertificatesRequest} certificatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCertificates: async (certificatesRequest: CertificatesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificatesRequest' is not null or undefined
            assertParamExists('generateCertificates', 'certificatesRequest', certificatesRequest)
            const localVarPath = `/certificate/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certificatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get available standards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStandards: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/certificate/standards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificatesApi - functional programming interface
 * @export
 */
export const CertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary generate certificates
         * @param {CertificatesRequest} certificatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCertificates(certificatesRequest: CertificatesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCertificates(certificatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get available standards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableStandards(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableStandards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificatesApi - factory interface
 * @export
 */
export const CertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary generate certificates
         * @param {CertificatesRequest} certificatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCertificates(certificatesRequest: CertificatesRequest, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.generateCertificates(certificatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get available standards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStandards(options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.getAvailableStandards(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificatesApi - object-oriented interface
 * @export
 * @class CertificatesApi
 * @extends {BaseAPI}
 */
export class CertificatesApi extends BaseAPI {
    /**
     * 
     * @summary generate certificates
     * @param {CertificatesRequest} certificatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public generateCertificates(certificatesRequest: CertificatesRequest, options?: any) {
        return CertificatesApiFp(this.configuration).generateCertificates(certificatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get available standards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public getAvailableStandards(options?: any) {
        return CertificatesApiFp(this.configuration).getAvailableStandards(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add client
         * @param {ClientModel} clientModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClient: async (clientModel: ClientModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientModel' is not null or undefined
            assertParamExists('addClient', 'clientModel', clientModel)
            const localVarPath = `/clients/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete client
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('deleteClient', 'clientId', clientId)
            const localVarPath = `/clients/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get list of clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get paginated list of clients
         * @param {PaginatedClientsRequest} paginatedClientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedClients: async (paginatedClientsRequest: PaginatedClientsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paginatedClientsRequest' is not null or undefined
            assertParamExists('getPaginatedClients', 'paginatedClientsRequest', paginatedClientsRequest)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedClientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add client
         * @param {ClientModel} clientModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClient(clientModel: ClientModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClient(clientModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete client
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get list of clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClients(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get paginated list of clients
         * @param {PaginatedClientsRequest} paginatedClientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedClients(paginatedClientsRequest: PaginatedClientsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedClients(paginatedClientsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary add client
         * @param {ClientModel} clientModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClient(clientModel: ClientModel, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.addClient(clientModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete client
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(clientId: number, options?: any): AxiosPromise<ListInvoiceResponse> {
            return localVarFp.deleteClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get list of clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(options?: any): AxiosPromise<ListInvoiceResponse> {
            return localVarFp.getClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get paginated list of clients
         * @param {PaginatedClientsRequest} paginatedClientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedClients(paginatedClientsRequest: PaginatedClientsRequest, options?: any): AxiosPromise<ListInvoiceResponse> {
            return localVarFp.getPaginatedClients(paginatedClientsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary add client
     * @param {ClientModel} clientModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public addClient(clientModel: ClientModel, options?: any) {
        return ClientsApiFp(this.configuration).addClient(clientModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete client
     * @param {number} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public deleteClient(clientId: number, options?: any) {
        return ClientsApiFp(this.configuration).deleteClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get list of clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getClients(options?: any) {
        return ClientsApiFp(this.configuration).getClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get paginated list of clients
     * @param {PaginatedClientsRequest} paginatedClientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getPaginatedClients(paginatedClientsRequest: PaginatedClientsRequest, options?: any) {
        return ClientsApiFp(this.configuration).getPaginatedClients(paginatedClientsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeesApi - axios parameter creator
 * @export
 */
export const EmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add employee
         * @param {NewEmployeeRequest} newEmployeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployee: async (newEmployeeRequest: NewEmployeeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newEmployeeRequest' is not null or undefined
            assertParamExists('addEmployee', 'newEmployeeRequest', newEmployeeRequest)
            const localVarPath = `/employees/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newEmployeeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete employee
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee: async (employeeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteEmployee', 'employeeId', employeeId)
            const localVarPath = `/employees/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get paginated list of employees
         * @param {PaginatedEmployeesRequest} paginatedEmployeesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedEmployees: async (paginatedEmployeesRequest: PaginatedEmployeesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paginatedEmployeesRequest' is not null or undefined
            assertParamExists('getPaginatedEmployees', 'paginatedEmployeesRequest', paginatedEmployeesRequest)
            const localVarPath = `/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedEmployeesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeesApi - functional programming interface
 * @export
 */
export const EmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add employee
         * @param {NewEmployeeRequest} newEmployeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployee(newEmployeeRequest: NewEmployeeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployee(newEmployeeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete employee
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployee(employeeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployee(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get paginated list of employees
         * @param {PaginatedEmployeesRequest} paginatedEmployeesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedEmployees(paginatedEmployeesRequest: PaginatedEmployeesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedEmployees(paginatedEmployeesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeesApi - factory interface
 * @export
 */
export const EmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeesApiFp(configuration)
    return {
        /**
         * 
         * @summary add employee
         * @param {NewEmployeeRequest} newEmployeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployee(newEmployeeRequest: NewEmployeeRequest, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.addEmployee(newEmployeeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete employee
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee(employeeId: number, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.deleteEmployee(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get paginated list of employees
         * @param {PaginatedEmployeesRequest} paginatedEmployeesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedEmployees(paginatedEmployeesRequest: PaginatedEmployeesRequest, options?: any): AxiosPromise<ListInvoiceResponse> {
            return localVarFp.getPaginatedEmployees(paginatedEmployeesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeesApi - object-oriented interface
 * @export
 * @class EmployeesApi
 * @extends {BaseAPI}
 */
export class EmployeesApi extends BaseAPI {
    /**
     * 
     * @summary add employee
     * @param {NewEmployeeRequest} newEmployeeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public addEmployee(newEmployeeRequest: NewEmployeeRequest, options?: any) {
        return EmployeesApiFp(this.configuration).addEmployee(newEmployeeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete employee
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public deleteEmployee(employeeId: number, options?: any) {
        return EmployeesApiFp(this.configuration).deleteEmployee(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get paginated list of employees
     * @param {PaginatedEmployeesRequest} paginatedEmployeesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getPaginatedEmployees(paginatedEmployeesRequest: PaginatedEmployeesRequest, options?: any) {
        return EmployeesApiFp(this.configuration).getPaginatedEmployees(paginatedEmployeesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add project
         * @param {ProjectModel} projectModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject: async (projectModel: ProjectModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectModel' is not null or undefined
            assertParamExists('addProject', 'projectModel', projectModel)
            const localVarPath = `/project/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get project details
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get projects
         * @param {PaginatedProjectsRequest} paginatedProjectsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (paginatedProjectsRequest: PaginatedProjectsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paginatedProjectsRequest' is not null or undefined
            assertParamExists('getProjects', 'paginatedProjectsRequest', paginatedProjectsRequest)
            const localVarPath = `/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedProjectsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add project
         * @param {ProjectModel} projectModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProject(projectModel: ProjectModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProject(projectModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get project details
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get projects
         * @param {PaginatedProjectsRequest} paginatedProjectsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(paginatedProjectsRequest: PaginatedProjectsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(paginatedProjectsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary add project
         * @param {ProjectModel} projectModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(projectModel: ProjectModel, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.addProject(projectModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: number, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.deleteProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get project details
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: number, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get projects
         * @param {PaginatedProjectsRequest} paginatedProjectsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(paginatedProjectsRequest: PaginatedProjectsRequest, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.getProjects(paginatedProjectsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary add project
     * @param {ProjectModel} projectModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public addProject(projectModel: ProjectModel, options?: any) {
        return ProjectsApiFp(this.configuration).addProject(projectModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(projectId: number, options?: any) {
        return ProjectsApiFp(this.configuration).deleteProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get project details
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(projectId: number, options?: any) {
        return ProjectsApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get projects
     * @param {PaginatedProjectsRequest} paginatedProjectsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(paginatedProjectsRequest: PaginatedProjectsRequest, options?: any) {
        return ProjectsApiFp(this.configuration).getProjects(paginatedProjectsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxApi - axios parameter creator
 * @export
 */
export const TaxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add vat invoice
         * @param {string} searchPeriod 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {FatoraDetails} fatoraDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVatInvoice: async (searchPeriod: string, pageNumber: number, pageSize: number, fatoraDetails: FatoraDetails, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPeriod' is not null or undefined
            assertParamExists('addVatInvoice', 'searchPeriod', searchPeriod)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('addVatInvoice', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('addVatInvoice', 'pageSize', pageSize)
            // verify required parameter 'fatoraDetails' is not null or undefined
            assertParamExists('addVatInvoice', 'fatoraDetails', fatoraDetails)
            const localVarPath = `/vat/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (searchPeriod !== undefined) {
                localVarQueryParameter['searchPeriod'] = (searchPeriod as any instanceof Date) ?
                    (searchPeriod as any).toISOString() :
                    searchPeriod;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fatoraDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get login captcha
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captcha: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vat/captcha`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete form 10
         * @param {string} declarationId 
         * @param {string} taxPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteForm10: async (declarationId: string, taxPeriod: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'declarationId' is not null or undefined
            assertParamExists('deleteForm10', 'declarationId', declarationId)
            // verify required parameter 'taxPeriod' is not null or undefined
            assertParamExists('deleteForm10', 'taxPeriod', taxPeriod)
            const localVarPath = `/vat/form10`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (declarationId !== undefined) {
                localVarQueryParameter['declarationId'] = declarationId;
            }

            if (taxPeriod !== undefined) {
                localVarQueryParameter['taxPeriod'] = (taxPeriod as any instanceof Date) ?
                    (taxPeriod as any).toISOString() :
                    taxPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete vat invoice
         * @param {string} searchPeriod 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVatInvoice: async (searchPeriod: string, page: number, pageSize: number, invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPeriod' is not null or undefined
            assertParamExists('deleteVatInvoice', 'searchPeriod', searchPeriod)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('deleteVatInvoice', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('deleteVatInvoice', 'pageSize', pageSize)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('deleteVatInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/vat/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (searchPeriod !== undefined) {
                localVarQueryParameter['searchPeriod'] = (searchPeriod as any instanceof Date) ?
                    (searchPeriod as any).toISOString() :
                    searchPeriod;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download form 10 document
         * @param {string} declarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadForm10: async (declarationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'declarationId' is not null or undefined
            assertParamExists('downloadForm10', 'declarationId', declarationId)
            const localVarPath = `/vat/form10/{declarationId}`
                .replace(`{${"declarationId"}}`, encodeURIComponent(String(declarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary extract all invoices for form 10
         * @param {string} searchPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractForm10: async (searchPeriod: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPeriod' is not null or undefined
            assertParamExists('extractForm10', 'searchPeriod', searchPeriod)
            const localVarPath = `/vat/form10/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (searchPeriod !== undefined) {
                localVarQueryParameter['searchPeriod'] = (searchPeriod as any instanceof Date) ?
                    (searchPeriod as any).toISOString() :
                    searchPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all form 10 records
         * @param {string} searchPeriodFrom 
         * @param {string} searchPeriodTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForm10: async (searchPeriodFrom: string, searchPeriodTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPeriodFrom' is not null or undefined
            assertParamExists('listForm10', 'searchPeriodFrom', searchPeriodFrom)
            // verify required parameter 'searchPeriodTo' is not null or undefined
            assertParamExists('listForm10', 'searchPeriodTo', searchPeriodTo)
            const localVarPath = `/vat/form10`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (searchPeriodFrom !== undefined) {
                localVarQueryParameter['searchPeriodFrom'] = (searchPeriodFrom as any instanceof Date) ?
                    (searchPeriodFrom as any).toISOString() :
                    searchPeriodFrom;
            }

            if (searchPeriodTo !== undefined) {
                localVarQueryParameter['searchPeriodTo'] = (searchPeriodTo as any instanceof Date) ?
                    (searchPeriodTo as any).toISOString() :
                    searchPeriodTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list vat invoices
         * @param {string} searchPeriod 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVatInvoices: async (searchPeriod: string, pageNumber: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPeriod' is not null or undefined
            assertParamExists('listVatInvoices', 'searchPeriod', searchPeriod)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listVatInvoices', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listVatInvoices', 'pageSize', pageSize)
            const localVarPath = `/vat/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (searchPeriod !== undefined) {
                localVarQueryParameter['searchPeriod'] = (searchPeriod as any instanceof Date) ?
                    (searchPeriod as any).toISOString() :
                    searchPeriod;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login to vat portal
         * @param {string} captchaText 
         * @param {VATLoginResponse} vATLoginResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login1: async (captchaText: string, vATLoginResponse: VATLoginResponse, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'captchaText' is not null or undefined
            assertParamExists('login1', 'captchaText', captchaText)
            // verify required parameter 'vATLoginResponse' is not null or undefined
            assertParamExists('login1', 'vATLoginResponse', vATLoginResponse)
            const localVarPath = `/vat/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (captchaText !== undefined) {
                localVarQueryParameter['captchaText'] = captchaText;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vATLoginResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary save form 10
         * @param {string} taxPeriod 
         * @param {string} submissionDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveForm10: async (taxPeriod: string, submissionDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxPeriod' is not null or undefined
            assertParamExists('saveForm10', 'taxPeriod', taxPeriod)
            // verify required parameter 'submissionDate' is not null or undefined
            assertParamExists('saveForm10', 'submissionDate', submissionDate)
            const localVarPath = `/vat/form10`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (taxPeriod !== undefined) {
                localVarQueryParameter['taxPeriod'] = (taxPeriod as any instanceof Date) ?
                    (taxPeriod as any).toISOString() :
                    taxPeriod;
            }

            if (submissionDate !== undefined) {
                localVarQueryParameter['submissionDate'] = (submissionDate as any instanceof Date) ?
                    (submissionDate as any).toISOString() :
                    submissionDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send form 10
         * @param {string} declarationId 
         * @param {string} taxPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm10: async (declarationId: string, taxPeriod: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'declarationId' is not null or undefined
            assertParamExists('sendForm10', 'declarationId', declarationId)
            // verify required parameter 'taxPeriod' is not null or undefined
            assertParamExists('sendForm10', 'taxPeriod', taxPeriod)
            const localVarPath = `/vat/form10`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (declarationId !== undefined) {
                localVarQueryParameter['declarationId'] = declarationId;
            }

            if (taxPeriod !== undefined) {
                localVarQueryParameter['taxPeriod'] = (taxPeriod as any instanceof Date) ?
                    (taxPeriod as any).toISOString() :
                    taxPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send form 10
         * @param {string} taxPeriod 
         * @param {Form10InputData} form10InputData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm10Data: async (taxPeriod: string, form10InputData: Form10InputData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxPeriod' is not null or undefined
            assertParamExists('sendForm10Data', 'taxPeriod', taxPeriod)
            // verify required parameter 'form10InputData' is not null or undefined
            assertParamExists('sendForm10Data', 'form10InputData', form10InputData)
            const localVarPath = `/vat/form10/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (taxPeriod !== undefined) {
                localVarQueryParameter['taxPeriod'] = (taxPeriod as any instanceof Date) ?
                    (taxPeriod as any).toISOString() :
                    taxPeriod;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form10InputData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxApi - functional programming interface
 * @export
 */
export const TaxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add vat invoice
         * @param {string} searchPeriod 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {FatoraDetails} fatoraDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addVatInvoice(searchPeriod: string, pageNumber: number, pageSize: number, fatoraDetails: FatoraDetails, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addVatInvoice(searchPeriod, pageNumber, pageSize, fatoraDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get login captcha
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async captcha(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VATLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.captcha(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete form 10
         * @param {string} declarationId 
         * @param {string} taxPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteForm10(declarationId: string, taxPeriod: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VatExtractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteForm10(declarationId, taxPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete vat invoice
         * @param {string} searchPeriod 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVatInvoice(searchPeriod: string, page: number, pageSize: number, invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVatInvoice(searchPeriod, page, pageSize, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary download form 10 document
         * @param {string} declarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadForm10(declarationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListForm10Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadForm10(declarationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary extract all invoices for form 10
         * @param {string} searchPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extractForm10(searchPeriod: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VatExtractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extractForm10(searchPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all form 10 records
         * @param {string} searchPeriodFrom 
         * @param {string} searchPeriodTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listForm10(searchPeriodFrom: string, searchPeriodTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListForm10Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listForm10(searchPeriodFrom, searchPeriodTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list vat invoices
         * @param {string} searchPeriod 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVatInvoices(searchPeriod: string, pageNumber: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVatInvoices(searchPeriod, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login to vat portal
         * @param {string} captchaText 
         * @param {VATLoginResponse} vATLoginResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login1(captchaText: string, vATLoginResponse: VATLoginResponse, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VATLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login1(captchaText, vATLoginResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary save form 10
         * @param {string} taxPeriod 
         * @param {string} submissionDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveForm10(taxPeriod: string, submissionDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VatExtractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveForm10(taxPeriod, submissionDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send form 10
         * @param {string} declarationId 
         * @param {string} taxPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendForm10(declarationId: string, taxPeriod: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VatExtractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendForm10(declarationId, taxPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send form 10
         * @param {string} taxPeriod 
         * @param {Form10InputData} form10InputData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendForm10Data(taxPeriod: string, form10InputData: Form10InputData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VatExtractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendForm10Data(taxPeriod, form10InputData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxApi - factory interface
 * @export
 */
export const TaxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxApiFp(configuration)
    return {
        /**
         * 
         * @summary add vat invoice
         * @param {string} searchPeriod 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {FatoraDetails} fatoraDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVatInvoice(searchPeriod: string, pageNumber: number, pageSize: number, fatoraDetails: FatoraDetails, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.addVatInvoice(searchPeriod, pageNumber, pageSize, fatoraDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get login captcha
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captcha(options?: any): AxiosPromise<VATLoginResponse> {
            return localVarFp.captcha(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete form 10
         * @param {string} declarationId 
         * @param {string} taxPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteForm10(declarationId: string, taxPeriod: string, options?: any): AxiosPromise<VatExtractResponse> {
            return localVarFp.deleteForm10(declarationId, taxPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete vat invoice
         * @param {string} searchPeriod 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVatInvoice(searchPeriod: string, page: number, pageSize: number, invoiceId: string, options?: any): AxiosPromise<ListInvoiceResponse> {
            return localVarFp.deleteVatInvoice(searchPeriod, page, pageSize, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download form 10 document
         * @param {string} declarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadForm10(declarationId: string, options?: any): AxiosPromise<ListForm10Response> {
            return localVarFp.downloadForm10(declarationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary extract all invoices for form 10
         * @param {string} searchPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractForm10(searchPeriod: string, options?: any): AxiosPromise<VatExtractResponse> {
            return localVarFp.extractForm10(searchPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all form 10 records
         * @param {string} searchPeriodFrom 
         * @param {string} searchPeriodTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForm10(searchPeriodFrom: string, searchPeriodTo: string, options?: any): AxiosPromise<ListForm10Response> {
            return localVarFp.listForm10(searchPeriodFrom, searchPeriodTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list vat invoices
         * @param {string} searchPeriod 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVatInvoices(searchPeriod: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<ListInvoiceResponse> {
            return localVarFp.listVatInvoices(searchPeriod, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login to vat portal
         * @param {string} captchaText 
         * @param {VATLoginResponse} vATLoginResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login1(captchaText: string, vATLoginResponse: VATLoginResponse, options?: any): AxiosPromise<VATLoginResponse> {
            return localVarFp.login1(captchaText, vATLoginResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary save form 10
         * @param {string} taxPeriod 
         * @param {string} submissionDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveForm10(taxPeriod: string, submissionDate: string, options?: any): AxiosPromise<VatExtractResponse> {
            return localVarFp.saveForm10(taxPeriod, submissionDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send form 10
         * @param {string} declarationId 
         * @param {string} taxPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm10(declarationId: string, taxPeriod: string, options?: any): AxiosPromise<VatExtractResponse> {
            return localVarFp.sendForm10(declarationId, taxPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send form 10
         * @param {string} taxPeriod 
         * @param {Form10InputData} form10InputData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm10Data(taxPeriod: string, form10InputData: Form10InputData, options?: any): AxiosPromise<VatExtractResponse> {
            return localVarFp.sendForm10Data(taxPeriod, form10InputData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxApi - object-oriented interface
 * @export
 * @class TaxApi
 * @extends {BaseAPI}
 */
export class TaxApi extends BaseAPI {
    /**
     * 
     * @summary add vat invoice
     * @param {string} searchPeriod 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {FatoraDetails} fatoraDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public addVatInvoice(searchPeriod: string, pageNumber: number, pageSize: number, fatoraDetails: FatoraDetails, options?: any) {
        return TaxApiFp(this.configuration).addVatInvoice(searchPeriod, pageNumber, pageSize, fatoraDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get login captcha
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public captcha(options?: any) {
        return TaxApiFp(this.configuration).captcha(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete form 10
     * @param {string} declarationId 
     * @param {string} taxPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public deleteForm10(declarationId: string, taxPeriod: string, options?: any) {
        return TaxApiFp(this.configuration).deleteForm10(declarationId, taxPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete vat invoice
     * @param {string} searchPeriod 
     * @param {number} page 
     * @param {number} pageSize 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public deleteVatInvoice(searchPeriod: string, page: number, pageSize: number, invoiceId: string, options?: any) {
        return TaxApiFp(this.configuration).deleteVatInvoice(searchPeriod, page, pageSize, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download form 10 document
     * @param {string} declarationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public downloadForm10(declarationId: string, options?: any) {
        return TaxApiFp(this.configuration).downloadForm10(declarationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary extract all invoices for form 10
     * @param {string} searchPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public extractForm10(searchPeriod: string, options?: any) {
        return TaxApiFp(this.configuration).extractForm10(searchPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all form 10 records
     * @param {string} searchPeriodFrom 
     * @param {string} searchPeriodTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public listForm10(searchPeriodFrom: string, searchPeriodTo: string, options?: any) {
        return TaxApiFp(this.configuration).listForm10(searchPeriodFrom, searchPeriodTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list vat invoices
     * @param {string} searchPeriod 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public listVatInvoices(searchPeriod: string, pageNumber: number, pageSize: number, options?: any) {
        return TaxApiFp(this.configuration).listVatInvoices(searchPeriod, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login to vat portal
     * @param {string} captchaText 
     * @param {VATLoginResponse} vATLoginResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public login1(captchaText: string, vATLoginResponse: VATLoginResponse, options?: any) {
        return TaxApiFp(this.configuration).login1(captchaText, vATLoginResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary save form 10
     * @param {string} taxPeriod 
     * @param {string} submissionDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public saveForm10(taxPeriod: string, submissionDate: string, options?: any) {
        return TaxApiFp(this.configuration).saveForm10(taxPeriod, submissionDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send form 10
     * @param {string} declarationId 
     * @param {string} taxPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public sendForm10(declarationId: string, taxPeriod: string, options?: any) {
        return TaxApiFp(this.configuration).sendForm10(declarationId, taxPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send form 10
     * @param {string} taxPeriod 
     * @param {Form10InputData} form10InputData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxApi
     */
    public sendForm10Data(taxPeriod: string, form10InputData: Form10InputData, options?: any) {
        return TaxApiFp(this.configuration).sendForm10Data(taxPeriod, form10InputData, options).then((request) => request(this.axios, this.basePath));
    }
}


