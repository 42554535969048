import { CButton, CCardFooter, CCardHeader, CCol, CRow } from "@coreui/react";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StepWizard from "react-step-wizard";
import { ClientsAPI, ProjectsAPI } from "../../../../helpers/ApiHelper";
import { isValidForm } from "../../../../helpers/ValidationHelper";
import ProjectInfo from "./project-info";
import ProjectStages from "../project-stages/project-stages";
import SubProjects from "../sub-projects/subprojects";

const NewProjectForm = (formProps) => {
  // console.log(formProps);
  const [clientsList, setClientsList] = useState([]);
  const projectStatusList = useSelector((state) => {
    if (!state.globalSlicer.cache.PROJECT_STATUS) {
      return [];
    }

    const mapped = state.globalSlicer.cache.PROJECT_STATUS.map((item) => {
      return {
        key: item.lookupId,
        value: item.lookupName,
        label: item.lookupName,
        object: item,
      };
    });
    return mapped;
  });

  const projectStagesStatusList = useSelector((state) => {
    if (!state.globalSlicer.cache.PROJECT_STAGE_STATUS) {
      return [];
    }

    const mapped = state.globalSlicer.cache.PROJECT_STAGE_STATUS.map((item) => {
      return {
        key: item.lookupId,
        value: item.lookupName,
        label: item.lookupName,
        object: item,
      };
    });
    return mapped;
  });

  const projectDefaultStages = useSelector((state) => {
    if (!state.globalSlicer.cache.DEFAULT_PROJECT_STAGES) {
      return [];
    }
    const mapped = state.globalSlicer.cache.DEFAULT_PROJECT_STAGES.map((item) => {
      return {
        stageDesc: item.lookupName,
        object: item,
      };
    });
    return mapped
  });

  const [projectItem, setProjectItem] = useState({
    client: undefined,
    projectName: "",
    projectDesc: "",
    startDate: undefined,
    plannedStartDate: undefined,
    plannedEndDate: undefined,
    actualEndDate: undefined,
    subProjects: [],
    projectStages: [...projectDefaultStages],
  });
  const [validationArray, setValidationArray] = useState([]);

  const saveProject = () => {
    const project = { ...projectItem, client: projectItem.client.object };
    ProjectsAPI.addProject(project);
    formProps.setReloadData(true);
    formProps.showEntryModal(false);
  };

  const stepHeader = (props) => {
    return (
      <CCardHeader>
        <CRow alignVertical="center">
          <CCol className="font-weight-bold">
            {props.stepName} - {props.currentStep}/{props.totalSteps}
          </CCol>
        </CRow>
      </CCardHeader>
    );
  };

  const stepFooter = (props) => {
    return (
      <CCardFooter>
        <CRow alignHorizontal="center" style={{ paddingBottom: "inherit" }}>
          {props.currentStep > 1 &&
            <CCol xs="3">
              <CButton
                block
                shape="pill"
                variant="outline"
                color="primary"
                className=""
                onClick={() => {
                  props.previousStep();
                }}
              >
                Previous
              </CButton>
            </CCol>
          }
          {props.currentStep === props.totalSteps &&
            <CCol xs="3">
              <CButton
                block
                shape="pill"
                color="primary"
                className=""
                onClick={() => {
                  const valid = isValidForm(
                    Object.fromEntries(
                      Object.entries(projectItem).filter(
                        (item) =>
                          item[0] !== "subProjects" &&
                          item[0] !== "projectStages" &&
                          item[0] !== "projectDesc" &&
                          item[0] !== "startDate" &&
                          item[0] !== "plannedStartDate" &&
                          item[0] !== "plannedEndDate" &&
                          item[0] !== "actualEndDate"
                      )
                    ),
                    setValidationArray
                  );
                  // console.log(valid);
                  if (valid) {
                    // console.log("saving", projectItem);
                    saveProject();
                  } else {
                    // console.log("not valid", validationArray);
                  }
                }}
              >
                Save
              </CButton>
            </CCol>
          }
          {props.currentStep < props.totalSteps &&
            <CCol xs="3">
              <CButton
                block
                shape="pill"
                variant="outline"
                color="primary"
                className=""
                onClick={() => {
                  props.nextStep();
                }}
              >
                Next
              </CButton>
            </CCol>
          }
        </CRow>
      </CCardFooter>
    );
  };

  useEffect(() => {
    ClientsAPI.getClients().then((data) => {
      const clientsList = data.data.map((item) => {
        return {
          key: item.clientId,
          value: item.arabicName,
          label: item.arabicName + " - " + item.englishName,
          object: item,
        };
      });
      setClientsList(clientsList);
    });
  }, []);

  return (
    <>
      {/* <LoadingOverlay active={loading} spinner text={loadingMessage}> */}
      <CRow>
        <CCol xs="12" sm="12">
          <StepWizard
            //   transitions={{
            //     enterRight: "animate__animated animate__fadeInRight",
            //     enterLeft: "animate__animated animate__fadeInLeft",
            //     exitRight: "animate__animated animate__fadeOutLeft",
            //     exitLeft: "animate__animated animate__fadeOutRight",
            //   }}
            isLazyMount={true}
          >
            <ProjectInfo
              projectItem={projectItem}
              validationArray={validationArray}
              setValidationArray={setValidationArray}
              setProjectItem={setProjectItem}
              stepHeader={stepHeader}
              stepFooter={stepFooter}
              stepName="Project information"
              clients={clientsList}
              projectStatusList={projectStatusList}
            ></ProjectInfo>
            <SubProjects
              projectItem={projectItem}
              setProjectItem={setProjectItem}
              stepHeader={stepHeader}
              stepFooter={stepFooter}
              stepName="Sub-projects"
            ></SubProjects>
            <ProjectStages
              projectItem={projectItem}
              setProjectItem={setProjectItem}
              stepHeader={stepHeader}
              stepFooter={stepFooter}
              stepName="Project stages"
              projectStagesStatusList={projectStagesStatusList}
            ></ProjectStages>
          </StepWizard>
        </CCol>
      </CRow>
      {/* </LoadingOverlay> */}
    </>
  );
};

export default NewProjectForm;
