import { CContainer, CFade } from '@coreui/react'
import React from 'react'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
// routes config
import routes from '../routes';
import { useSelector } from 'react-redux';

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

const TheContent = () => {
  // console.log('contenttttt');
  const filteredNavs = useSelector((state) => state.globalSlicer.filteredNavigations);
  // console.log(filteredNavs);
  let firstNav = filteredNavs[0];
  // console.log('firstNav', firstNav);
  while (firstNav && firstNav._children) {
    firstNav = firstNav._children[0];
  }
  // console.log(filteredNavs);
  // console.log('firstNav', firstNav);
  return (
    <main className="c-main">
      <CContainer fluid>
        {/* <Suspense fallback={loading}> */}
        <Switch>
          {routes.map((route, idx) => {
            // console.log(route, idx);
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => {
                  // console.log(props);
                  return (
                    <CFade>
                      <route.component {...props} {...route.routeProps} />
                    </CFade>
                  );
                }} />
            )
          })}

            <Redirect from="/" to={firstNav.to} />

        </Switch>
        {/* </Suspense> */}
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
