import jwtDecode from "jwt-decode";
import moment from "moment";
import navigation from "../containers/_nav";
import { AuthenticationAPI } from "../helpers/ApiHelper";
import { updateIsLoggedIn } from "../redux/slicers/GlobalSlicer";
import { globalTimeouts, REFRESH_TOKEN_BEFORE_EXPIRY_DURATION } from "./Variables";

export const getAuthToken = () => {
  const token = localStorage.getItem("AUTHORIZATION");
  return token ? `bearer ${token}` : "";
};

export const getCurrentTimeInSeconds = () => {
  return (Date.now() / 1000) | 0;
};

export function executeFunctionAt(time, func) {
  var currentTime = new Date().getTime();
  const timeout = setTimeout(func, time * 1000 - currentTime);
  globalTimeouts.push(timeout);
}

export const readBlobAsJson = (file) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error());
    };
    fileReader.onload = () => {
      resolve(JSON.parse(fileReader.result || ""));
    };
    fileReader.readAsText(file);
  });
};

export const updateLastAction = () => {
  const lastActionTime = (Date.now() / 1000) | 0;
  localStorage.setItem("LAST_ACTION_TIME", `${lastActionTime}`);
};

export const didLogin = () => {
  const token = getAuthToken();
  localStorage.setItem("LAST_REFRESH_TIME", `${getCurrentTimeInSeconds()}`);
  // console.log('logged in');
  scheduleTokenCheck(token);
  window.onfocus = () => {
    if (getAuthToken() && isTokenExpired()) {
      logout();
    }
  };
};

export const checkIsLoggedIn = () => {
  let isLoggedIn = !!localStorage.getItem("AUTHORIZATION");
  if (isLoggedIn) {
    const token = localStorage.getItem("AUTHORIZATION");
    AuthenticationAPI.isLoggedIn(token)
      .then((data) => {
        if (data.status !== 200) {
          logout();
        }
      })
      .catch((error) => {
        isLoggedIn = false;
        logout();
      });
  } else {
    logout();
  }

};

export const scheduleTokenCheck = (token) => {
  const tokenExpireTime = jwtDecode(token).exp;
  const checkTime = tokenExpireTime - REFRESH_TOKEN_BEFORE_EXPIRY_DURATION;
  // console.log('check time: ', tokenExpireTime, REFRESH_TOKEN_BEFORE_EXPIRY_DURATION, checkTime);
  executeFunctionAt(checkTime, checkToken);
};

const checkToken = () => {
  // console.log('checkToken');
  try {
    if (isTokenExpired()) {
      // console.log('expired');
      return;
    }
    // console.log('here');
    const lastActionTime = Number(localStorage.getItem("LAST_ACTION_TIME"));
    // console.log('here2');
    const lastRefreshTime = Number(localStorage.getItem("LAST_REFRESH_TIME"));
    // console.log('here3');
    if (lastActionTime > lastRefreshTime) {
      // console.log('here4');
      refreshToken();
      // console.log('here5');
    }
  } catch (error) {
    console.error(error);
  }
};

const isTokenExpired = () => {
  const tokenExpireTime = jwtDecode(getAuthToken()).exp;
  const currentTime = moment().unix();
  return currentTime > tokenExpireTime;
};

const refreshToken = async () => {
  // console.log('refreshing');
  const {
    data: { token: newToken },
  } = await AuthenticationAPI.refresh(getAuthToken());
  localStorage.setItem("AUTHORIZATION", newToken);
  localStorage.setItem("LAST_REFRESH_TIME", `${getCurrentTimeInSeconds()}`);
  scheduleTokenCheck(newToken);
};

export const logout = (callback) => {
  try {
    // console.log('loggingOut2');
    window.location.hash = "#/";
    localStorage.clear();
    if (callback) {
      callback(updateIsLoggedIn(false));
    }
    while (globalTimeouts.length > 0) {
      const timeout = globalTimeouts.pop();
      clearTimeout(timeout);
    }
  } catch (e) { }
};

export const getAvailableNavs = (modules) => {
  // console.log('modules', modules);
  const filterNavs = (item) => {
    if (item._children) return item;
    let module = modules.find(element => {
      // console.log(element, item);
      return element.moduleURL === '*' || element.moduleURL === item.to
        || element.moduleURL.replace("{room_number}", element.roomNo) === item.to
    });
    return module ? true : false;
  }
  let filteredNavs = JSON.parse(JSON.stringify(navigation));
  filteredNavs = filteredNavs.filter(item => filterNavs(item));
  // console.log(filteredNavs);
  filteredNavs.forEach(item => {
    if (item._children) {
      // console.log(item, item._children);
      item._children = item._children.filter(subItem => filterNavs(subItem));
    }
  });
  // console.log('availableNavs', filteredNavs);
  return filteredNavs;
}

export const base64ToBlob = (base64, mime) => {
  mime = mime || '';
  var sliceSize = 1024;
  var byteChars = window.atob(base64);
  var byteArrays = [];

  for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}