import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput, CInputFile, CInputRadio, CLabel, CListGroup,
  CListGroupItem, CRow
} from "@coreui/react";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import EditableLabel from "react-inline-editing";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { CertificatesAPI } from "../../../helpers/ApiHelper";
import { readCertificatesFile } from "../../../helpers/ExcelHelper";

const CertificatesGenerator = () => {
  const [certificateFile, setCertificateFile] = useState();
  const [formDetails, setFormDetails] = useState({
    courseName: "",
    courseLocation: "",
    fromDate: "",
    toDate: "",
    isArabic: true,
    isOneDay: true,
    isAttend: true,
    standards: [],
    attendees: [],
  });
  const [standardsSelectOptions, setStandardsSelectOptions] = useState([]);
  const [formValidation, setFormValidation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Retrieving standards");

  useEffect(() => {
    setLoading(true);
    CertificatesAPI.getAvailableStandards().then((data) => {
      if (data.status === 200) {
        const availableStds = data.data.map((item) => {
          return {
            key: item.standardId,
            value: item.standardName,
            label: item.standardName,
            object: item,
          };
        });
        setStandardsSelectOptions(availableStds);
      }
      setLoading(false);
    });
  }, []);

  // const addToast = (title, message) => {
  //   setToasts([
  //     ...toasts,
  //     {
  //       position: "top-right",
  //       autohide: 3000,
  //       closeButton: true,
  //       fade: true,
  //       title: title,
  //       message: message,
  //     },
  //   ]);
  // };

  const onResetClick = () => {
    setFormDetails({
      courseName: "",
      courseLocation: "",
      fromDate: "",
      toDate: "",
      isArabic: true,
      isOneDay: true,
      isAttend: true,
      standards: [],
      attendees: [],
    });
    setFormValidation([]);
  };

  const onCertificateFileChange = (event) => {
    if (event.target.value != null) {
      if (!event.target.value.endsWith(".xlsx")) {
        return;
      }
      setLoadingMessage("Loading certificates file");
      setLoading(true);
      setFormValidation([]);
      if (event.target.id === "reload") {
        // console.log(certificateFile);
        readCertificatesFile(certificateFile, certificateFileResult);
      } else {
        // console.log(event);
        event.persist();
        setCertificateFile(event);
        event.preventDefault();
        readCertificatesFile(event, certificateFileResult);
      }
      // setLoading(false);
    }
  };

  const certificateFileResult = (formData) => {
    formData.standards = formData.standards.map((item) => {
      return standardsSelectOptions.find((standard) => {
        return standard.value === item;
      });
    });
    // console.log(formData);
    setFormDetails(formData);
    setLoading(false);
  };

  const validateFormField = (fieldName, fieldValue, isSubmit) => {
    let formValidationsArray = [...formValidation];
    if (!fieldValue || fieldValue.length === 0) {
      formValidationsArray.push(fieldName);
    } else {
      if (formValidationsArray.includes(fieldName)) {
        formValidationsArray.splice(formValidationsArray.indexOf(fieldName), 1);
      }
      // console.log(formValidationsArray);
    }
    if (!isSubmit) {
      setFormValidation(formValidationsArray);
    }
    return formValidationsArray;
  };

  const onGenerateClick = () => {
    let formValidationsArray = [];
    // Object.entries(formDetails).forEach((item) => {
    //   console.log("validating: ", item[0], item[1]);
    //   formValidationsArray = [
    //     ...formValidationsArray,
    //     ...validateFormField(item[0], item[1]),
    //   ];
    // });
    if (formValidationsArray.length > 0) {
      setFormValidation(formValidationsArray);
      return false;
    }
    setLoading(true);
    setLoadingMessage("Generating Certificates");
    // console.log(formDetails);
    // const requestForm = {
    //   ...formDetails,
    //   fromDate: moment(formDetails.fromDate).format("DD/MM/yyyy"),
    //   toDate: moment(formDetails.toDate).format("DD/MM/yyyy"),
    // };
    // console.log(requestForm);
    // console.log(formDetails);
    const requestObject = {
      ...formDetails,
      standards: formDetails.standards
        .filter((item) => item != null)
        .map((item) => item.object),
    };
    // console.log("requestObject", requestObject);

    CertificatesAPI.generateCertificates(requestObject, {
      responseType: "blob",
    })
      .then((data) => {
        // console.log(data);
        var blob = new Blob([data.data]);
        saveAs(
          blob,
          formDetails.courseName + "-" + formDetails.courseLocation + ".pdf"
        );
        setLoading(false);
      })
      .catch((error) => {
        // console.log('error',error.response);
        // addToast("Failure", "Failed to add Invoice");
        setLoading(false);
      });

    // TaxAPI.addVatInvoice(
    //   moment(searchPeriod).format("MM/yyyy"),
    //   activePage,
    //   itemsPerPage,
    //   requestForm
    // )
    //   .then((data) => {
    //     // console.log('heree',data);
    //     if (data.status === 200) {
    //       setInvoiceList(
    //         data.data.data.map((item) => ({
    //           ...item,
    //           invoiceDate: moment(
    //             new Date(
    //               item.invoiceDate
    //                 .replaceAll("/", "")
    //                 .replace("Date(", "")
    //                 .replace(")", "") * 1
    //             )
    //           ).format("DD-MM-YYYY"),
    //         }))
    //       );
    //       setLoading(false);
    //       setTotalNumber(Math.ceil(data.data.total / itemsPerPage));
    //     } else {
    //       addToast("Failure", "Failed to add Invoice");
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log('error',error.response);
    //     addToast("Failure", "Failed to add Invoice");
    //     setLoading(false);
    //   });
  };

  const onDeleteClick = (clickedItem) => {
    setLoadingMessage("Deleting attendee");
    setLoading(true);
    // console.log(formDetails.attendees.indexOf(clickedItem));
    const attendeesCopy = [...formDetails.attendees];
    attendeesCopy.splice(attendeesCopy.indexOf(clickedItem), 1);
    // console.log(attendeesCopy);
    setFormDetails({ ...formDetails, attendees: attendeesCopy });
    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner text={loadingMessage}>
        <CRow>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>
                <CRow alignVertical="center">
                  <CCol className="font-weight-bold">
                    Certificate information
                  </CCol>
                  <CCol>
                    <div className="card-header-actions">
                      <CInputFile
                        id="loadCertificates"
                        name="loadCertificates"
                        onChange={onCertificateFileChange}
                        multiple={false}
                      />
                    </div>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel className="font-weight-bold" htmlFor="courseName">
                        Course name
                      </CLabel>
                      <CInput
                        id="courseName"
                        invalid={formValidation.indexOf("courseName") >= 0}
                        placeholder="Course Name"
                        required
                        value={formDetails.courseName}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            courseName: val.target.value.trim(),
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="courseLocation"
                      >
                        Course location
                      </CLabel>
                      <CInput
                        id="courseLocation"
                        placeholder="Course Location"
                        invalid={formValidation.indexOf("courseLocation") >= 0}
                        required
                        min="0"
                        value={formDetails.courseLocation}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            courseLocation: val.target.value.trim(),
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel className="font-weight-bold" htmlFor="fromDate">
                        From
                      </CLabel>
                      <CInput
                        type="date"
                        id="fromDate"
                        name="fromDate"
                        invalid={formValidation.indexOf("fromDate") >= 0}
                        placeholder="fromDate"
                        max={moment(new Date()).format("yyyy-MM-DD")}
                        min="2010-01-01"
                        value={
                          formDetails.fromDate
                            ? moment(formDetails.fromDate).format("yyyy-MM-DD")
                            : formDetails.fromDate
                        }
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            fromDate: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel className="font-weight-bold" htmlFor="toDate">
                        To
                      </CLabel>
                      <CInput
                        type="date"
                        id="toDate"
                        name="toDate"
                        invalid={formValidation.indexOf("toDate") >= 0}
                        placeholder="toDate"
                        max={moment(new Date()).format("yyyy-MM-DD")}
                        min="2010-01-01"
                        value={
                          formDetails.toDate
                            ? moment(formDetails.toDate).format("yyyy-MM-DD")
                            : formDetails.toDate
                        }
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            toDate: val.target.value,
                          });
                          validateFormField(
                            val.target.id,
                            val.target.value,
                            false
                          );
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="5">
                    <CFormGroup>
                      <CLabel
                        className="font-weight-bold"
                        htmlFor="customerName"
                      >
                        Standards
                      </CLabel>
                      <Select
                        showSearch={true}
                        placeholder="Select Standards"
                        isClearable
                        isSearchable
                        isMulti
                        invalid={formValidation.indexOf("standards") >= 0}
                        value={formDetails.standards}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            standards: [...val],
                          });
                          validateFormField("standards", val, false);
                        }}
                        options={standardsSelectOptions}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs="2">
                    <CLabel
                      className="font-weight-bold"
                      htmlFor="languageBoxes"
                    >
                      Language
                    </CLabel>
                    <CFormGroup id="languageBoxes" variant="checkbox">
                      <CInputRadio
                        className="form-check-input"
                        id="arabic"
                        name="language"
                        value="arabic"
                        checked={formDetails.isArabic}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            isArabic: true,
                          });
                        }}
                      />
                      <CLabel variant="checkbox" htmlFor="arabic">
                        Arabic
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="checkbox">
                      <CInputRadio
                        className="form-check-input"
                        id="english"
                        name="language"
                        value="english"
                        checked={!formDetails.isArabic}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            isArabic: false,
                          });
                        }}
                      />
                      <CLabel variant="checkbox" htmlFor="english">
                        English
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                  <CCol xs="2">
                    <CLabel className="font-weight-bold" htmlFor="">
                      Duration
                    </CLabel>
                    <CFormGroup variant="checkbox">
                      <CInputRadio
                        className="form-check-input"
                        id="oneDay"
                        name="duration"
                        value="oneDay"
                        checked={formDetails.isOneDay}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            isOneDay: true,
                          });
                        }}
                      />
                      <CLabel variant="checkbox" htmlFor="oneDay">
                        One day
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="checkbox">
                      <CInputRadio
                        className="form-check-input"
                        id="multiDay"
                        name="duration"
                        value="multiDay"
                        checked={!formDetails.isOneDay}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            isOneDay: false,
                          });
                        }}
                      />
                      <CLabel variant="checkbox" htmlFor="multiDay">
                        Multi-day
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                  <CCol xs="2">
                    <CLabel className="font-weight-bold" htmlFor="">
                      Course type
                    </CLabel>
                    <CFormGroup variant="checkbox">
                      <CInputRadio
                        className="form-check-input"
                        id="attend"
                        name="courseType"
                        value="attended"
                        checked={formDetails.isAttend}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            isAttend: true,
                          });
                        }}
                      />
                      <CLabel variant="checkbox" htmlFor="attended">
                        Attended
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="checkbox">
                      <CInputRadio
                        className="form-check-input"
                        id="passed"
                        name="courseType"
                        value="paased"
                        checked={!formDetails.isAttend}
                        onChange={(val) => {
                          setFormDetails({
                            ...formDetails,
                            isAttend: false,
                          });
                        }}
                      />
                      <CLabel variant="checkbox" htmlFor="passed">
                        Passed
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <CRow alignHorizontal="center">
                  <CCol xs="2" className="mb-3 mb-xl-0">
                    <CButton
                      block
                      shape="pill"
                      variant="outline"
                      color="primary"
                      className=""
                      onClick={onGenerateClick}
                    >
                      Generate
                    </CButton>
                  </CCol>
                  {/* <CCol xs="2" className="mb-3 mb-xl-0">
                    <CButton
                      block
                      shape="pill"
                      variant="outline"
                      color="secondary"
                      onClick={onCertificateFileChange}
                      id="reload"
                    >
                      Reload
                    </CButton>
                  </CCol> */}
                  <CCol xs="2" className="mb-3 mb-xl-0">
                    <CButton
                      block
                      shape="pill"
                      variant="ghost"
                      color="secondary"
                      onClick={onResetClick}
                    >
                      Reset
                    </CButton>
                  </CCol>
                </CRow>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        {/* ################################# LIST OF ATTENDEES #################################  */}
        <CRow>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>
                <CRow alignVertical="center">
                  <CCol className="font-weight-bold">Attendees List</CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CListGroup
                  style={{ maxHeight: 300 }}
                  className="overflow-auto"
                >
                  {formDetails.attendees.map((attendee) => (
                    <CListGroupItem
                      key={attendee.__rowNum__}
                      className="justify-content-between"
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <CIcon
                            style={{
                              cursor: "pointer",
                              color: "red",
                              marginRight: 10,
                            }}
                            name="cil-trash"
                            onClick={() => onDeleteClick(attendee)}
                          />
                        </div>
                        <div>
                          <EditableLabel
                            inputWidth="50px"
                            // text={attendee.title + " / " + attendee.name}
                            text={attendee.title}
                            onFocusOut={(val) => {
                              const attendeesCopy = [...formDetails.attendees];
                              attendeesCopy[
                                attendeesCopy.indexOf(attendee)
                              ].title = val;
                              setFormDetails({
                                ...formDetails,
                                attendees: attendeesCopy,
                              });
                            }}
                          />
                        </div>
                        <div>&nbsp;{" / "}&nbsp;</div>
                        <div>
                          <EditableLabel
                            inputWidth="200px"
                            // text={attendee.title + " / " + attendee.name}
                            text={attendee.name}
                            onFocusOut={(val) => {
                              const attendeesCopy = [...formDetails.attendees];
                              attendeesCopy[
                                attendeesCopy.indexOf(attendee)
                              ].name = val;
                              setFormDetails({
                                ...formDetails,
                                attendees: attendeesCopy,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <CBadge className="float-right" shape="pill" color="primary">14</CBadge> */}
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </LoadingOverlay>
    </>
  );
};

export default CertificatesGenerator;
