import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
      <a href="http://www.aecegypt.org" target="_blank" rel="noopener noreferrer">Copyright &copy; 2020 - All Rights Reserved - aecegypt.com</a>
      </div>     
    </CFooter>
  )
}

export default React.memo(TheFooter)
