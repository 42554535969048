import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupAppend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import {
  isValidForm,
  isValidField,
} from "../../../../helpers/ValidationHelper";
import { ClientsAPI } from "../../../../helpers/ApiHelper";
import { useEffect, useState } from "react";

const NewClientForm = (formProps) => {
  useEffect(() => {}, []);
  const [client, setClient] = useState({
    arabicName: undefined,
    englishName: undefined,
    address: undefined,
    taxId: undefined,
  });
  const [validationArray, setValidationArray] = useState([]);

  const saveClient = () => {
    ClientsAPI.addClient(client);
    formProps.setReloadData(true);
    formProps.showEntryModal(false);
  };

  return (
    <>
      {/* <LoadingOverlay active={loading} spinner text={loadingMessage}> */}
      <CRow>
        <CCol xs="12" sm="12">
          <CCard>
            <CCardBody>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Arabic Name
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="arabicName"
                      name="arabicName"
                      autoComplete="arabicName"
                      placeholder="Enter Client's Arabic name"
                      value={client.arabicName}
                      invalid={validationArray?.indexOf("arabicName") >= 0}
                      onChange={(val) => {
                        setClient((oldVal) => {
                          return { ...oldVal, arabicName: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        English Name
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="englishName"
                      name="englishName"
                      autoComplete="englishName"
                      placeholder="Enter Client's Engllish name"
                      value={client.englishName}
                      invalid={validationArray?.indexOf("englishName") >= 0}
                      onChange={(val) => {
                        setClient((oldVal) => {
                          return { ...oldVal, englishName: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Address
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="address"
                      name="address"
                      autoComplete="address"
                      placeholder="Enter Client's address"
                      value={client.address}
                      invalid={validationArray?.indexOf("address") >= 0}
                      onChange={(val) => {
                        setClient((oldVal) => {
                          return { ...oldVal, address: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Tax ID
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="number"
                      id="taxId"
                      name="taxId"
                      autoComplete="taxId"
                      placeholder="Enter Client's Tax ID"
                      value={client.taxId}
                      invalid={validationArray?.indexOf("taxId") >= 0}
                      onChange={(val) => {
                        setClient((oldVal) => {
                          return { ...oldVal, taxId: val.target.value };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="3">
                  <CButton
                    block
                    shape="pill"
                    variant="outline"
                    color="primary"
                    className=""
                    onClick={() => {
                      const valid = isValidForm(client, setValidationArray);
                      // console.log(valid);
                      if (valid) {
                        // console.log("saving", client);
                        saveClient();
                      } else {
                        // console.log("not valid", validationArray);
                      }
                    }}
                  >
                    Save
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/* </LoadingOverlay> */}
    </>
  );
};

export default NewClientForm;
