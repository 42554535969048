import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CPagination,
  CRow,
  CSelect,
} from "@coreui/react";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ClientsAPI } from "../../../../helpers/ApiHelper";
import NewClientForm from "../new-client/new-client-form";

const ClientsList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Retrieving clients");
  const [entryModalShown, showEntryModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalNumber, setTotalNumber] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [tableFilter, setTableFilter] = useState("");
  const [searchPressed, setSearchPressed] = useState(false);
  const [sortingObject, setSortingObject] = useState({
    clientId: "ASC",
    arabicName: "ASC",
    englishName: "ASC",
    taxId: "ASC",
  });

  const onDeleteClick = (clickedItem) => {
    setLoading(true);
    setLoadingMessage("Deleting Client");
    ClientsAPI.deleteClient(clickedItem.clientId)
    .then((data) => {   
      setReloadData(true); 
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
  };

  const loadData = () => {
    let sortingArr = Object.entries(sortingObject).map(
      (entry) => entry[0] + "_" + entry[1]
    );
    // console.log(sortingArr);
    let pageNum = 0;
    // console.log(activePage);
    // console.log(totalNumber > 0 ? activePage - 1 : activePage);
    if (activePage) {
      pageNum = totalNumber > 0 ? activePage - 1 : activePage;
    }
    // console.log("dateeeeeeeeeess");
    // console.log(startDate);
    // console.log(endDate);
    const paginatedClientsRequest = {
      // from: startDate ? moment(startDate).format("DD.MM.YYYY") : null,
      // to: endDate ? moment(endDate).format("DD.MM.YYYY") : null,
      sortingArray: sortingArr,
      pageNum: pageNum,
      pageSize: itemsPerPage,
      filterQuery: tableFilter,
    };
    // console.log('req', paginatedClientsRequest);
    ClientsAPI.getPaginatedClients(paginatedClientsRequest)
      .then((data) => {
        setTotalNumber(data.data.totalElements);
        const clientsList = data.data.content.map((item) => {
          // const startDate = item.startDate
          //   ? moment(item.startDate).format("DD-MM-YYYY")
          //   : "";
          // const plannedEnd = item.plannedEnd
          //   ? moment(item.plannedEnd).format("DD-MM-YYYY")
          //   : "";
          // const actualEnd = item.actualEnd
          //   ? moment(item.actualEnd).format("DD-MM-YYYY")
          //   : "";
          return {
            ...item,
            // startDate: startDate,
            // plannedEnd: plannedEnd,
            // actualEnd: actualEnd,
          };
        });
        setSearchPressed(false);
        setClients(clientsList);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMessage("Loading clients");
    let isMounted = true;
    if (isMounted) {
      loadData();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage, sortingObject, searchPressed, reloadData]);

  return (
    <>
      {entryModalShown ? (
        <CModal
          style={{ margin: "auto auto", width: "60%" }}
          show={entryModalShown}
          size={"lg"}
          onClose={() => showEntryModal(false)}
        >
          <CModalHeader closeButton>
            <CModalTitle>Add new Client</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <NewClientForm
              showEntryModal={showEntryModal}
              setReloadData={setReloadData}
            ></NewClientForm>
          </CModalBody>
        </CModal>
      ) : (
        <></>
      )}
      <LoadingOverlay active={loading} spinner text={loadingMessage}>
        <CRow>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>
                <CRow alignVertical="center">
                  <CCol className="font-weight-bold">Clients list</CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow style={{ paddingBottom: "inherit" }}>
                  <CCol xs="12">
                    <CButton
                      type="button"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        showEntryModal(true);
                      }}
                    >
                      <CIcon
                        style={{
                          cursor: "pointer",
                          color: "white",
                        }}
                        name="cilPlus"
                      />
                      New Client
                    </CButton>
                  </CCol>
                </CRow>
                <CRow alignHorizontal="center">
                  <CCol xs="12">
                    <CDataTable
                      items={clients}
                      noItemsView={{
                        noResults: "No Available clients",
                        noItems: "No Available clients",
                      }}
                      fields={[
                        { key: "delete", label: "", _style: { width: "1%" }, filter: false },
                        {
                          key: "clientId",
                          label: "#",
                          _style: { width: "1%" },
                          filter: false
                        },
                        { key: "arabicName", label: "Client Name" },
                        { key: "address", label: "Address" },
                        { key: "projects", label: "Projects" },
                      ]}
                      footer
                      sorter={{ external: true, resetable: true }}
                      onSorterValueChange={(item) => {
                        // console.log('item', item);
                        if (item.column) {
                          setSortingObject((oldItems) => {
                            let itemObj = {};
                            itemObj[item.column] = item.asc ? "ASC" : "DESC";
                            // console.log('itemObj', itemObj);
                            return { ...oldItems, ...itemObj };
                          });
                        }
                      }}
                      columnFilter
                      size="sm"
                      hover
                      striped
                      itemsPerPage={itemsPerPage}
                      scopedSlots={{
                        delete: (item) => (
                          <td
                            style={{ textAlign: "center", color: "red" }}
                            onClick={(i) => onDeleteClick(item)}
                          >
                            <CIcon
                              style={{ cursor: "pointer" }}
                              name="cil-trash"
                            />
                          </td>
                        ),
                        projects: (item) => (
                          <td>
                            {item.projectCount > 0 ? (
                              <a href="../projects">
                                {"Explore " +
                                  item.projectCount +
                                  " Project" +
                                  (item.projectCount > 1 ? "s" : "")}
                              </a>
                            ) : (
                              "No Projects to Explore"
                            )}
                          </td>
                        ),
                        // projectStatus: (item) => (
                        //     <td>{item.projectStatus.lookupName}</td>
                        // ),
                      }}
                      overTableSlot={
                        <CFormGroup row>
                          <CCol md="5">
                            <CInputGroup>
                              <CInputGroupPrepend>
                                <CButton
                                  type="button"
                                  color="primary"
                                  style={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    if (
                                      !tableFilter ||
                                      tableFilter.trim() === ""
                                    ) {
                                      setTableFilter("");
                                    }
                                    setSearchPressed(true);
                                  }}
                                >
                                  <CIcon name="cil-magnifying-glass" /> Search
                                </CButton>
                              </CInputGroupPrepend>
                              <CInput
                                value={tableFilter || ""}
                                onChange={(val) => {
                                  setTableFilter(val.target.value);
                                }}
                                placeholder="name, client, status, dates ...."
                                style={{ height: "inherit" }}
                              />
                              {tableFilter && tableFilter.trim() !== "" ? (
                                <CInputGroupAppend>
                                  <CButton
                                    type="button"
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                    onClick={() => {
                                      if (
                                        tableFilter &&
                                        tableFilter.trim() !== ""
                                      ) {
                                        setTableFilter("");
                                        setSearchPressed(true);
                                      }
                                    }}
                                  >
                                    <CIcon name="cil-x-circle" />
                                  </CButton>
                                </CInputGroupAppend>
                              ) : (
                                <></>
                              )}
                            </CInputGroup>
                          </CCol>
                          <CCol
                            md="5"
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            {/* <strong
                              style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                              Period
                            </strong>

                            <RangePicker
                              style={{
                                height: "inherit",
                                borderColor: showDateError
                                  ? "red"
                                  : "rgb(216, 219, 224)",
                              }}
                              allowClear={true}
                              defaultValue={[startDate, endDate]}
                              onChange={(dates) => {
                                console.log("dates", dates);
                                if (dates) {
                                  var duration = moment.duration(
                                    dates[1].diff(dates[0])
                                  );
                                  var days = duration.asDays();
                                  // console.log('days', days);
                                  if (days < 5) {
                                    setShowDateError(false);
                                    setStartDate(dates[0]);
                                    setEndDate(dates[1]);
                                  } else {
                                    setShowDateError(true);
                                  }
                                } else {
                                  setShowDateError(false);
                                  setStartDate(undefined);
                                  setEndDate(undefined);
                                }
                              }}
                              disabledDate={(current) =>
                                current &&
                                (current < moment().startOf("year") ||
                                  current > moment())
                              }
                              format={"MM/DD/YYYY"}
                              separator={"To"}
                              placeholder={["Period start", "Period end"]}
                            />
                            {showDateError ? (
                              <font style={{ color: "red", paddingRight: 10 }}>
                                Please choose up to 5 days only
                              </font>
                            ) : (
                              <></>
                            )}*/}
                          </CCol>
                          <CCol md="2">
                            <CInputGroup>
                              <CInputGroupPrepend>
                                <CInputGroupText
                                  className={
                                    "bg-transparent text-black border-0 font-weight-bold"
                                  }
                                >
                                  Page size
                                </CInputGroupText>
                              </CInputGroupPrepend>
                              <CSelect
                                onChange={(i) => {
                                  setItemsPerPage(i.target.value);
                                }}
                                custom
                                name="cItemsPerPage"
                                id="cItemsPerPage"
                                value={itemsPerPage}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </CSelect>
                            </CInputGroup>
                          </CCol>
                        </CFormGroup>
                      }
                      underTableSlot={
                        totalNumber > 0 ? (
                          <CPagination
                            align="center"
                            activePage={activePage}
                            pages={Math.ceil(totalNumber / itemsPerPage)}
                            // itemsPerPage={itemsPerPage}
                            onActivePageChange={(i) => {
                              setActivePage(i);
                            }}
                          ></CPagination>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </LoadingOverlay>
    </>
  );
};

export default ClientsList;
