import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import EventSystem from "../helpers/EventSystem";
import {
  TheContent, TheFooter,
  TheHeader, TheSidebar
} from './index';

const TheLayout = (props) => {
  const history = useHistory();
  // const filteredNavs = useSelector((state) => state.globalSlicer.filteredNavigations);
  // console.log('history', history);

  useEffect(() => {
    // console.log('layout hereeeee');
    EventSystem.subscribe("UNAUTHORIZED_RESOURCE", () => {
      // console.log('pushing 401 to history');
      history.push('/401');
    });
    // console.log('props.location.pathname', props);
    // if (props.location.pathname !== '/401' && props.location.pathname !== '/') {
    //   // console.log('hereee');
    //   const nav = filteredNavs.find(element => {
    //     return element.to === props.location.pathname ||
    //       (element._children && element._children.length > 0 && element._children.find(subElement => {
    //         return subElement.to === props.location.pathname;
    //       }))
    //       || (props.location.pathname.startsWith("/project") && element.to === "/history");
    //   });

    //   console.log('nav',props.location.pathname, filteredNavs, nav);
    //   if (!nav) {
    //     history.push('/401');
    //   }
    // } 
    return () => {
      EventSystem.unsubscribe("UNAUTHORIZED_RESOURCE");
    }
  });


  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  )
}

export default TheLayout
