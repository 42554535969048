import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CDataTable,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CPagination,
  CRow,
  CSelect,
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
} from "@coreui/react";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { TaxAPI } from "../../../helpers/ApiHelper";
import { saveAs } from "file-saver";
import { base64ToBlob } from "../../../helpers/Utils";


const { RangePicker } = DatePicker;

const Form10List = () => {
  const [toasts, setToasts] = useState([]);
  const [captchaModalShown, showCaptchaModal] = useState(false);
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaDeText, setCaptchaDeText] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [form10Items, setForm10Items] = useState([]);
  const [form10Pages, setForm10Pages] = useState([]);
  const [extractedData, setExtractedData] = useState();
  // const [submissionDate, setSubmissionDate] = useState();
  const [taxPeriod, setTaxPeriod] = useState(moment().subtract(1, "months"));
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Retrieving form 10 items"
  );
  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoadingMessage, setModalLoadingMessage] = useState(
    "Extracting invoices Data"
  );
  const [reloadData, setReloadData] = useState(false);
  const SYSTEM_CONFIG = useSelector(
    (state) => state.globalSlicer.cache.SYSTEM_CONFIG
  );
  const [form10InputData, setForm10Inputdata] = useState({
    applicantName: undefined,
    nationalPassNum: undefined,
    representitive: undefined,
    signedDate: moment(),
  });

  const [entryModalShown, showEntryModal] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "months").format("yyyy-MM").toString()
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("yyyy-MM").toString()
  );
  // const [showDateError, setShowDateError] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);

  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  const addToast = (title, message) => {
    setToasts([
      ...toasts,
      {
        position: "top-right",
        autohide: 3000,
        closeButton: true,
        fade: true,
        title: title,
        message: message,
      },
    ]);
  };

  const toMatrix = (arr, width) => {
    return arr.reduce(
      (rows, key, index) =>
        (index % width == 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows,
      []
    );
  };

  const loadData = () => {
    TaxAPI.listForm10(
      moment(startDate, "yyyy-MM").format("MM/yyyy").toString(),
      moment(endDate, "yyyy-MM").format("MM/yyyy").toString()
    )
      .then((data) => {
        // setTotalNumber(data.data.totalElements);
        // setSearchPressed(false);
        // console.log(data.data.data);
        // console.log(Math.ceil(data.data.data.length / itemsPerPage));
        setTotalNumber(Math.ceil(data.data.data.length / itemsPerPage));
        setForm10Items(data.data.data);
        setForm10Pages(toMatrix(data.data.data, itemsPerPage));
        // setForm10Items(
        //   data.data.content.map((item) => {
        //     // console.log(item);
        //     return {
        //       ...item,
        //       startDate: item.startDate ? item.startDate : "N/A",
        //       plannedEndDate: item.plannedEndDate ? item.plannedEndDate : "N/A",
        //       actualEndDate: item.actualEndDate ? item.actualEndDate : "N/A",
        //     };
        //   })
        // );
        setLoading(false);
      })
      .catch(error => {
        // console.log(error.response.status);
        if (error.response.status == 401) {
          TaxAPI.captcha()
            // .then(response => response.blob())
            .then((captchaResponse) => {
              setCaptchaDeText(captchaResponse.data.captchaDeText);
              setVerificationToken(captchaResponse.data.verificationToken);
              const blob = base64ToBlob(captchaResponse.data.captchaImage);
              const url = URL.createObjectURL(blob);
              setCaptchaImageUrl(url);
              showCaptchaModal(true);
              setLoading(false);
            })

        } else {
          addToast("Failure", "Failed to list form10");
        }
        setLoading(false);
      });
  };

  const form10FormModalFooter = (props) => {
    return (
      <CCardFooter>
        <CRow alignHorizontal="center" style={{ paddingBottom: "inherit" }}>
          {/* <CCol xs="3">
            <CButton
              block
              shape="pill"
              variant="outline"
              color="primary"
              className=""
              onClick={() => {
                TaxAPI.extractForm10(
                  moment(taxPeriod).format("MM/YYYY").toString()
                )
                  .then((data) => {
                    console.log(data);
                    setExtractedData(data.data.data);
                  })
                  .catch((error) => console.log(error));
              }}
            >
              Extract
            </CButton>
          </CCol> */}
          {/* <CCol xs="3">
            <CButton
              block
              shape="pill"
              variant="outline"
              color="primary"
              className=""
              onClick={() => {
                TaxAPI.saveForm10(
                  moment(taxPeriod).format("MM/YYYY").toString(),
                  moment(form10InputData.SignedDate).format("DD/MM/YYYY")
                )
                  .then((resp) => console.log(resp))
                  .catch((error) => console.log(error));
              }}
            >
              Save
            </CButton>
          </CCol> */}
          <CCol xs="3">
            <CButton
              block
              shape="pill"
              // variant="outline"
              color="primary"
              className=""
              onClick={() => {
                form10InputData.signedDate = moment(form10InputData)
                  .format("DD/MM/YYYY")
                  .toString();
                // console.log(form10InputData);
                TaxAPI.sendForm10Data(
                  moment(taxPeriod).format("MM/YYYY").toString(),
                  form10InputData
                )
                  .then((resp) => {
                    // console.log(resp);
                    setReloadData(true);
                    showEntryModal(false);
                  })
                  .catch(error => {
                    // console.log(error.response.status);
                    if (error.response.status == 401) {
                      TaxAPI.captcha()
                        // .then(response => response.blob())
                        .then((captchaResponse) => {
                          setCaptchaDeText(captchaResponse.data.captchaDeText);
                          setVerificationToken(captchaResponse.data.verificationToken);
                          const blob = base64ToBlob(captchaResponse.data.captchaImage);
                          const url = URL.createObjectURL(blob);
                          setCaptchaImageUrl(url);
                          showCaptchaModal(true);
                          setLoading(false);
                        })
            
                    } else {
                      addToast("Failure", "Failed to send form10 data");
                    }
                    setLoading(false);
                  });
              }}
            >
              Save & Send
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    );
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMessage("Loading form 10 items");
    let isMounted = true;
    if (isMounted) {
      loadData();
    }
    if (SYSTEM_CONFIG) {
      setForm10Inputdata({
        applicantName: SYSTEM_CONFIG.FORM10_APPLICANT_NAME,
        nationalPassNum: SYSTEM_CONFIG.FORM10_APPLICANT_ID,
        representitive: SYSTEM_CONFIG.FORM10_APPLICANT_REPRESENTITIVE,
        signedDate: moment(),
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // activePage,
    // itemsPerPage,
    // sortingObject,
    startDate,
    endDate,
    // searchPressed,
    reloadData,
    SYSTEM_CONFIG,
  ]);

  return (
    <>
      {captchaModalShown ? (
        <CModal
          style={{ margin: "auto auto", width: "25%" }}
          show={captchaModalShown}
          size={"lg"}
          onClose={() => showCaptchaModal(false)}
        >
          <CModalHeader closeButton>
            <CModalTitle>Enter Captcha for re-Authentication</CModalTitle>
          </CModalHeader>
          <CModalBody style={{ margin: "auto auto" }} >
            <img src={captchaImageUrl} alt="Dynamic from binary" />
            <CInput
              id="captchaCode"
              placeholder="Captcha Code"
              required
              value={captchaCode}
              onChange={(val) => {
                setCaptchaCode(val.target.value);
              }}
            />
            <CButton
              block
              shape="pill"
              // variant="outline"
              color="primary"
              className=""
              onClick={() => {
                TaxAPI.login1(captchaCode, { captchaText: captchaCode, captchaDeText: captchaDeText, verificationToken: verificationToken })
                  .then((data) => {
                    console.log(data.data);
                    setLoading(false);
                    showCaptchaModal(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Login
            </CButton>
          </CModalBody>
        </CModal>
      ) : (
        <></>
      )}
      {entryModalShown ? (
        <CModal
          style={{ margin: "auto auto ", width: "70%" }}
          show={entryModalShown}
          size={"lg"}
          onClose={() => {
            showEntryModal(false);
          }}
        >
          {" "}
          <CModalHeader closeButton>
            <CModalTitle>Add new form 10</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <LoadingOverlay
              active={modalLoading}
              spinner
              text={modalLoadingMessage}
            >
              {/* <NewProjectForm
              showEntryModal={showEntryModal}
              setReloadData={setReloadData}
            ></NewProjectForm> */}
              <CRow>
                <CCol sm="12" lg="6">
                  {Object.keys(toasters).map((toasterKey) => (
                    <CToaster position={toasterKey} key={"toaster" + toasterKey}>
                      {toasters[toasterKey].map((toast, key) => {
                        return (
                          <CToast
                            key={"toast" + key}
                            show={true}
                            autohide={toast.autohide}
                            fade={toast.fade}
                          >
                            <CToastHeader closeButton={toast.closeButton}>
                              {toast.title}
                            </CToastHeader>
                            <CToastBody>
                              {toast.message}
                              {/* {`This is a toast in ${toasterKey} positioned toaster number ${key + 1}.`} */}
                            </CToastBody>
                          </CToast>
                        );
                      })}
                    </CToaster>
                  ))}
                </CCol>
                <CCol xs="12" sm="12">
                  <CCard>
                    <CCardBody>
                      <CRow
                        alignHorizontal="center"
                        style={{ paddingBottom: "inherit" }}
                      >
                        <CCol xs="6">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Total Income
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="text"
                              id="totalIncome"
                              name="totalIncome"
                              autoComplete="totalIncome"
                              placeholder="Total income"
                              disabled={true}
                              value={extractedData?.lstMergeAllTaxTable[0].totalValue.toLocaleString(
                                "en-US",
                                { minimumFractionDigits: 2 }
                              )}
                            />
                          </CInputGroup>
                        </CCol>
                        <CCol xs="6">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Total Vat
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="text"
                              id="totalVat"
                              name="totalVat"
                              autoComplete="totalVat"
                              placeholder="Total Vat"
                              disabled={true}
                              value={extractedData?.lstMergeAllTaxTable[0].tax.toLocaleString(
                                "en-US",
                                { minimumFractionDigits: 2 }
                              )}
                            />
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow
                        alignHorizontal="center"
                        style={{ paddingBottom: "inherit" }}
                      >
                        <CCol xs="12">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Applicant name
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="text"
                              id="applicantName"
                              name="applicantName"
                              autoComplete="applicantName"
                              placeholder="Enter Applicant name"
                              // defaultValue={SYSTEM_CONFIG.FORM10_APPLICANT_NAME}
                              disabled={true}
                              value={form10InputData.applicantName}
                              onChange={(val) => {
                                setForm10Inputdata((old) => {
                                  old.applicantName = val;
                                  return old;
                                });
                              }}
                            // invalid={
                            //   // props.validationArray?.indexOf("projectName") >= 0
                            // }
                            // onChange={(val) => {
                            // props.setProjectItem((oldVal) => {
                            //   return {
                            //     ...oldVal,
                            //     projectName: val.target.value,
                            //   };
                            // });
                            // isValidField(
                            //   val.target.id,
                            //   val.target.value,
                            //   props.validationArray,
                            //   props.setValidationArray
                            // );
                            // }}
                            />
                            {/* <CInputGroupAppend>
                              <CInputGroupText
                                className={"bg-white text-danger border-0"}
                              >
                                *
                              </CInputGroupText>
                            </CInputGroupAppend> */}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow
                        alignHorizontal="center"
                        style={{ paddingBottom: "inherit" }}
                      >
                        <CCol xs="12">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Applicant ID
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="text"
                              id="applicantId"
                              name="applicantId"
                              autoComplete="applicantId"
                              placeholder="Enter Applicant ID"
                              // defaultValue={SYSTEM_CONFIG.FORM10_APPLICANT_ID}
                              disabled={true}
                              value={form10InputData.nationalPassNum}
                              onChange={(val) => {
                                setForm10Inputdata((old) => {
                                  old.nationalPassNum = val;
                                  return old;
                                });
                              }}
                            // invalid={
                            //   // props.validationArray?.indexOf("projectName") >= 0
                            // }
                            // onChange={(val) => {
                            // props.setProjectItem((oldVal) => {
                            //   return {
                            //     ...oldVal,
                            //     projectName: val.target.value,
                            //   };
                            // });
                            // isValidField(
                            //   val.target.id,
                            //   val.target.value,
                            //   props.validationArray,
                            //   props.setValidationArray
                            // );
                            // }}
                            />
                            {/* <CInputGroupAppend>
                              <CInputGroupText
                                className={"bg-white text-danger border-0"}
                              >
                                *
                              </CInputGroupText>
                            </CInputGroupAppend> */}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow
                        alignHorizontal="center"
                        style={{ paddingBottom: "inherit" }}
                      >
                        <CCol xs="12">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Applicant Title
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="text"
                              id="applicantTitle"
                              name="applicantTitle"
                              autoComplete="applicantTitle"
                              placeholder="Enter Applicant Title"
                              // defaultValue={
                              //   SYSTEM_CONFIG.FORM10_APPLICANT_REPRESENTITIVE
                              // }
                              disabled={true}
                              value={form10InputData.representitive}
                              onChange={(val) => {
                                setForm10Inputdata((old) => {
                                  old.representitive = val;
                                  return old;
                                });
                              }}
                            // invalid={
                            //   // props.validationArray?.indexOf("projectName") >= 0
                            // }
                            // onChange={(val) => {
                            // props.setProjectItem((oldVal) => {
                            //   return {
                            //     ...oldVal,
                            //     projectName: val.target.value,
                            //   };
                            // });
                            // isValidField(
                            //   val.target.id,
                            //   val.target.value,
                            //   props.validationArray,
                            //   props.setValidationArray
                            // );
                            // }}
                            />
                            {/* <CInputGroupAppend>
                              <CInputGroupText
                                className={"bg-white text-danger border-0"}
                              >
                                *
                              </CInputGroupText>
                            </CInputGroupAppend> */}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow
                        alignHorizontal="center"
                        style={{ paddingBottom: "inherit" }}
                      >
                        <CCol xs="6">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Submission date
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <DatePicker
                              style={{
                                height: "inherit",
                                // borderColor:
                                //   props.validationArray &&
                                //   props.validationArray?.indexOf("startDate") >= 0
                                //     ? "red"
                                //     : "rgb(216, 219, 224)",
                              }}
                              allowClear={true}
                              // defaultValue={moment()}
                              value={form10InputData.signedDate}
                              onChange={(val) => {
                                setForm10Inputdata((old) => {
                                  old.signedDate = val;
                                  return old;
                                });
                              }}
                              // value={submissionDate}
                              // onChange={(date) => {
                              //   console.log(date);
                              //   setSubmissionDate(date);
                              //   // if (date) {
                              //   //   props.setProjectItem((oldVal) => {
                              //   //     return {
                              //   //       ...oldVal,
                              //   //       startDate: moment(date).format("DD/MM/YYYY"),
                              //   //     };
                              //   //   });
                              //   // } else {
                              //   //   props.setProjectItem((oldVal) => {
                              //   //     return { ...oldVal, startDate: undefined };
                              //   //   });
                              //   // }
                              //   // isValidField(
                              //   //   "startDate",
                              //   //   date,
                              //   //   props.validationArray,
                              //   //   props.setValidationArray
                              //   // );
                              // }}
                              // disabledDate={(current) => current && current > moment()}
                              format={"DD/MM/YYYY"}
                            />
                            {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                          </CInputGroup>
                        </CCol>
                        <CCol xs="6">
                          <CInputGroup>
                            <CInputGroupPrepend>
                              <CInputGroupText
                                className={"bg-primary text-white"}
                              >
                                Tax Period
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <DatePicker
                              style={{
                                height: "inherit",
                                // borderColor:
                                //   props.validationArray &&
                                //   props.validationArray?.indexOf("startDate") >= 0
                                //     ? "red"
                                //     : "rgb(216, 219, 224)",
                              }}
                              allowClear={true}
                              picker={"month"}
                              defaultValue={moment().subtract(1, "months")}
                              value={taxPeriod}
                              onChange={(date) => {
                                // console.log(date);
                                setTaxPeriod(date);
                                // if (date) {
                                //   props.setProjectItem((oldVal) => {
                                //     return {
                                //       ...oldVal,
                                //       startDate: moment(date).format("DD/MM/YYYY"),
                                //     };
                                //   });
                                // } else {
                                //   props.setProjectItem((oldVal) => {
                                //     return { ...oldVal, startDate: undefined };
                                //   });
                                // }
                                // isValidField(
                                //   "startDate",
                                //   date,
                                //   props.validationArray,
                                //   props.setValidationArray
                                // );
                              }}
                              // disabledDate={(current) => current && current > moment()}
                              format={"MMM YYYY"}
                            />
                            {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </CCardBody>
                    {form10FormModalFooter()}
                  </CCard>
                </CCol>
              </CRow>
            </LoadingOverlay>
          </CModalBody>
        </CModal>
      ) : (
        <></>
      )}
      <LoadingOverlay active={loading} spinner text={loadingMessage}>
        <CRow>
          <CCol xs="12" sm="12">
            <CCard>
              <CCardHeader>
                <CRow alignVertical="center">
                  <CCol className="font-weight-bold">Form 10 list</CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow style={{ paddingBottom: "inherit" }}>
                  <CCol xs="12">
                    <CButton
                      type="button"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        setModalLoading(true);
                        TaxAPI.extractForm10(
                          moment(taxPeriod).format("MM/YYYY").toString()
                        )
                          .then((data) => {
                            // console.log(data);
                            setExtractedData(data.data.data);
                            setModalLoading(false);
                          })
                          .catch(error => {
                            // console.log(error.response.status);
                            if (error.response.status == 401) {
                              TaxAPI.captcha()
                                // .then(response => response.blob())
                                .then((captchaResponse) => {
                                  setCaptchaDeText(captchaResponse.data.captchaDeText);
                                  setVerificationToken(captchaResponse.data.verificationToken);
                                  const blob = base64ToBlob(captchaResponse.data.captchaImage);
                                  const url = URL.createObjectURL(blob);
                                  setCaptchaImageUrl(url);
                                  showCaptchaModal(true);
                                  setLoading(false);
                                })
                    
                            } else {
                              addToast("Failure", "Failed to extract form10");
                            }
                            setLoading(false);
                          });
                        showEntryModal(true);
                      }}
                    >
                      <CIcon
                        style={{
                          cursor: "pointer",
                          color: "white",
                        }}
                        name="cilPlus"
                      />{" "}
                      New Form10
                    </CButton>
                  </CCol>
                </CRow>
                <CRow alignHorizontal="center">
                  <CCol xs="12">
                    <CDataTable
                      items={form10Pages[activePage - 1]}
                      noItemsView={{
                        noResults: "No Available Form 10 items",
                        noItems: "No Available Form 10 items",
                      }}
                      fields={[
                        // { key: "delete", label: "" },
                        // {
                        //   key: "projectId",
                        //   label: "#",
                        //   _style: { width: "1%" },
                        // },
                        { key: "taxPeriod", label: "Tax Period" },
                        { key: "tableTaxTotal", label: "Table Tax Total" },
                        { key: "tableTaxVat", label: "Table Tax Vat" },
                        { key: "sendStatus", label: "Status" },
                        {
                          key: "view",
                          label: "",
                          _style: { width: "5%" },
                          sorter: false,
                          filter: false,
                        },
                        {
                          key: "send",
                          label: "",
                          _style: { width: "5%" },
                          sorter: false,
                          filter: false,
                        },
                        {
                          key: "delete",
                          label: "",
                          _style: { width: "5%" },
                          sorter: false,
                          filter: false,
                        },
                        // { key: "plannedEndDate", label: "Planned End Date" },
                        // { key: "actualEndDate", label: "Actual End Date" },
                      ]}
                      footer
                      sorter
                      columnFilter
                      size="sm"
                      hover
                      striped
                      scopedSlots={{
                        taxPeriod: (item) => {
                          return (
                            <td>
                              {moment(item.taxPeriod, "YYYYMM")
                                .format("MMM YY")
                                .toString()}
                            </td>
                          );
                        },
                        tableTaxVat: (item) => {
                          return (
                            <td>{item.tableTaxVat.toLocaleString("en-US")}</td>
                          );
                        },
                        tableTaxTotal: (item) => {
                          return (
                            <td>
                              {item.tableTaxDetailsList[0].totalValue.toLocaleString(
                                "en-US"
                              )}
                            </td>
                          );
                        },
                        view: (item) => (
                          <td style={{ textAlign: "center" }}>
                            <CButton
                              block
                              // disabled={item.object.state === 2 || item.object.state === 3}
                              shape="square"
                              color="primary"
                              className=""
                              onClick={(i) => {
                                // console.log(item);
                                TaxAPI.downloadForm10(item.tableVATID, {
                                  responseType: "blob",
                                }).then((data) => {
                                  var blob = new Blob([data.data]);
                                  saveAs(
                                    blob,
                                    moment(taxPeriod)
                                      .format("MMM-YYYY")
                                      .toString()
                                      .toUpperCase() + ".pdf"
                                  );
                                })
                                .catch(error => {
                                  // console.log(error.response.status);
                                  if (error.response.status == 401) {
                                    TaxAPI.captcha()
                                      // .then(response => response.blob())
                                      .then((captchaResponse) => {
                                        setCaptchaDeText(captchaResponse.data.captchaDeText);
                                        setVerificationToken(captchaResponse.data.verificationToken);
                                        const blob = base64ToBlob(captchaResponse.data.captchaImage);
                                        const url = URL.createObjectURL(blob);
                                        setCaptchaImageUrl(url);
                                        showCaptchaModal(true);
                                        setLoading(false);
                                      })
                          
                                  } else {
                                    addToast("Failure", "Failed to download form10");
                                  }
                                  setLoading(false);
                                });
                              }}
                            >
                              <CIcon
                                style={{ cursor: "pointer" }}
                                name="cil-file"
                              />
                            </CButton>
                          </td>
                        ),
                        send: (item) => (
                          <td style={{ textAlign: "center" }}>
                            {/* {item.send ? ( */}
                            <CButton
                              block
                              disabled={item.send}
                              shape="square"
                              color="success"
                              className=""
                              onClick={(i) => { }}
                            >
                              <CIcon
                                style={{ cursor: item.send ? "" : "pointer" }}
                                name="cil-send"
                              />
                            </CButton>
                            {/* ) : (
                              <></>
                            )} */}
                          </td>
                        ),
                        delete: (item) => (
                          <td style={{ textAlign: "center" }}>
                            {/* {item.send ? ( */}
                            <CButton
                              block
                              disabled={item.send}
                              shape="square"
                              color="danger"
                              className=""
                              onClick={(i) => { }}
                            >
                              <CIcon
                                style={{ cursor: item.send ? "" : "pointer" }}
                                name="cil-trash"
                              />
                            </CButton>
                            {/* ) : (
                              <></>
                            )} */}
                          </td>
                        ),
                      }}
                      underTableSlot={
                        totalNumber > 0 ? (
                          <CPagination
                            align="center"
                            activePage={activePage}
                            pages={totalNumber}
                            onActivePageChange={(i) => {
                              setActivePage(i);
                            }}
                          ></CPagination>
                        ) : (
                          <></>
                        )
                      }
                      overTableSlot={
                        <CRow alignHorizontal="end">
                          <CCol xs="3">
                            <CInputGroup>
                              <CInputGroupPrepend>
                                <CInputGroupText
                                  className={
                                    "bg-transparent text-black border-0 font-weight-bold"
                                  }
                                >
                                  Tax Period Start
                                </CInputGroupText>
                              </CInputGroupPrepend>
                              <CInput
                                type="month"
                                id="taxPeriodStart"
                                name="taxPeriodStart"
                                placeholder="taxPeriodStart"
                                defaultValue={startDate}
                                onChange={(i) => {
                                  setStartDate(
                                    moment(i.target.value)
                                      .format("yyyy-MM")
                                      .toString()
                                  );
                                }}
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol xs="3">
                            <CInputGroup>
                              <CInputGroupPrepend>
                                <CInputGroupText
                                  className={
                                    "bg-transparent text-black border-0 font-weight-bold"
                                  }
                                >
                                  Tax Period End
                                </CInputGroupText>
                              </CInputGroupPrepend>
                              <CInput
                                type="month"
                                id="taxPeriodEnd"
                                name="taxPeriodEnd"
                                placeholder="taxPeriodEnd"
                                defaultValue={endDate}
                                onChange={(i) => {
                                  setEndDate(
                                    moment(i.target.value)
                                      .format("yyyy-MM")
                                      .toString()
                                  );
                                }}
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol xs="3">
                            <CFormGroup>
                              <CInputGroup>
                                <CInputGroupPrepend>
                                  <CInputGroupText
                                    className={
                                      "bg-transparent text-black border-0 font-weight-bold"
                                    }
                                  >
                                    Items per page
                                  </CInputGroupText>
                                </CInputGroupPrepend>
                                <CSelect
                                  onChange={(i) => {
                                    setItemsPerPage(i.target.value);
                                    setActivePage(1);
                                    setForm10Pages((old) => {
                                      const pages = toMatrix(
                                        form10Items,
                                        i.target.value
                                      );
                                      // console.log(pages);
                                      return pages;
                                    });
                                  }}
                                  custom
                                  name="cItemsPerPage"
                                  id="cItemsPerPage"
                                  value={itemsPerPage}
                                >
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                </CSelect>
                              </CInputGroup>
                            </CFormGroup>
                          </CCol>
                        </CRow>
                      }
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </LoadingOverlay>
    </>
  );
};

export default Form10List;
