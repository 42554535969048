import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import {
  areValidDates,
  isValidField,
  isValidForm,
} from "../../../../helpers/ValidationHelper";

const ProjectStageDetails = (props) => {
  const DEFAULT_PROJECT_STAGE = {
    stageDesc: "",
    plannedStartDate: undefined,
    plannedEndDate: undefined,
    actualStartDate: undefined,
    actualEndDate: undefined,
  };
  const [projectStage, setProjectStage] = useState(DEFAULT_PROJECT_STAGE);
  const [validationArray, setValidationArray] = useState([]);
  const [projectStagesStatusList, setProjectStagesStatusList] = useState(
    props.projectStagesStatusList
  );

  useEffect(() => {
    setProjectStagesStatusList(props.projectStagesStatusList);
  }, [props.projectStagesStatusList]);

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12">
          <CCard>
            {props.stepHeader ? props.stepHeader(props) : <></>}
            <CCardBody>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Project stage description
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      id="stageDesc"
                      name="stageDesc"
                      autoComplete="stageDesc"
                      placeholder="Enter project stage Desc"
                      value={projectStage.stageDesc}
                      invalid={validationArray.indexOf("stageDesc") >= 0}
                      onChange={(val) => {
                        setProjectStage((oldVal) => {
                          return {
                            ...oldVal,
                            stageDesc: val.target.value,
                          };
                        });
                        isValidField(
                          val.target.id,
                          val.target.value,
                          validationArray,
                          setValidationArray
                        );
                      }}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="12">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Project Stage status
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <div
                      className="form-control"
                      style={{
                        padding: "0 0 0 0",
                        height: "fit-content",
                        borderWidth: 0,
                      }}
                    >
                      <Select
                        id="stageStatus"
                        showSearch={true}
                        placeholder="Select status"
                        isSearchable
                        value={
                          projectStage.stageStatus
                            ? projectStage.stageStatus
                            : projectStagesStatusList[0]
                        }
                        defaultValue={projectStagesStatusList[0]}
                        onChange={(val) => {
                          props.setProjectStage((oldVal) => {
                            return { ...oldVal, stageStatus: val };
                          });
                        }}
                        options={projectStagesStatusList}
                      />
                    </div>
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Planned start date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                          validationArray.indexOf("plannedStartDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={projectStage.plannedStartDate}
                      onChange={(date) => {
                        if (date) {
                          setProjectStage((oldVal) => {
                            return {
                              ...oldVal,
                              plannedStartDate:
                                moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setProjectStage((oldVal) => {
                            return { ...oldVal, plannedStartDate: undefined };
                          });
                        }
                        isValidField(
                          "plannedStartDate",
                          date,
                          validationArray,
                          setValidationArray
                        );
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                    {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                  </CInputGroup>
                </CCol>
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Planned end date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                          validationArray.indexOf("plannedEndDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={projectStage.plannedEndDate}
                      onChange={(date) => {
                        if (date) {
                          setProjectStage((oldVal) => {
                            return {
                              ...oldVal,
                              plannedEndDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setProjectStage((oldVal) => {
                            return { ...oldVal, plannedEndDate: undefined };
                          });
                        }
                        areValidDates(
                          "plannedEndDate",
                          projectStage.plannedStartDate,
                          date ? moment(date).format("DD/MM/YYYY") : undefined,
                          validationArray,
                          setValidationArray
                        );
                        // validateDate(
                        //   "plannedEndDate",
                        //   projectStage.plannedStartDate,
                        //   date ? moment(date).format("DD/MM/YYYY") : undefined
                        // );
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                    {/* <CInputGroupAppend>
                      <CInputGroupText
                        className={"bg-white text-danger border-0"}
                      >
                        *
                      </CInputGroupText>
                    </CInputGroupAppend> */}
                  </CInputGroup>
                  {projectStage.plannedEndDate &&
                  validationArray &&
                  validationArray.indexOf("plannedEndDate") >= 0 ? (
                    <font style={{ color: "red", paddingRight: 10 }}>
                      End date must be after start date
                    </font>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
              <CRow
                alignHorizontal="center"
                style={{ paddingBottom: "inherit" }}
              >
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Actual start date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor: "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={projectStage.actualStartDate}
                      onChange={(date) => {
                        if (date) {
                          setProjectStage((oldVal) => {
                            return {
                              ...oldVal,
                              actualStartDate:
                                moment(date).format("DD/MM/YYYY"),
                            };
                          });
                        } else {
                          setProjectStage((oldVal) => {
                            return { ...oldVal, actualStartDate: undefined };
                          });
                        }
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                  </CInputGroup>
                </CCol>
                <CCol xs="6">
                  <CInputGroup>
                    <CInputGroupPrepend>
                      <CInputGroupText className={"bg-primary text-white"}>
                        Actual end date
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <DatePicker
                      style={{
                        height: "inherit",
                        borderColor:
                          validationArray &&
                          validationArray.indexOf("actualEndDate") >= 0
                            ? "red"
                            : "rgb(216, 219, 224)",
                      }}
                      allowClear={true}
                      defaultValue={projectStage.actualEndDate}
                      onChange={(date) => {
                        if (date) {
                          setProjectStage((oldVal) => {
                            return {
                              ...oldVal,
                              actualEndDate: moment(date).format("DD/MM/YYYY"),
                            };
                          });
                          areValidDates(
                            "actualEndDate",
                            projectStage.actualStartDate,
                            date
                              ? moment(date).format("DD/MM/YYYY")
                              : undefined,
                            validationArray,
                            setValidationArray
                          );
                          // validateDate(
                          //   "actualEndDate",
                          //   projectStage.actualStartDate,
                          //   date ? moment(date).format("DD/MM/YYYY") : undefined
                          // );
                        } else {
                          setProjectStage((oldVal) => {
                            return { ...oldVal, actualEndDate: undefined };
                          });
                        }
                      }}
                      // disabledDate={(current) => current && current > moment()}
                      format={"DD/MM/YYYY"}
                    />
                  </CInputGroup>
                  {projectStage.actualEndDate &&
                  validationArray &&
                  validationArray.indexOf("actualEndDate") >= 0 ? (
                    <font style={{ color: "red", paddingRight: 10 }}>
                      End date must be after start date
                    </font>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
              <CRow alignHorizontal="left" style={{ paddingBottom: "inherit" }}>
                <CCol xs="3">
                  <CButton
                    block
                    shape="pill"
                    color="primary"
                    variant="outline"
                    className=""
                    onClick={() => {
                      if (
                        isValidForm(
                          Object.fromEntries(
                            Object.entries(projectStage).filter(
                              (item) =>
                                item[0] !== "plannedStartDate" &&
                                item[0] !== "plannedEndDate" &&
                                item[0] !== "actualStartDate" &&
                                item[0] !== "actualEndDate"
                            )
                          ),
                          setValidationArray
                        )
                      ) {
                        // if (validate(projectStage)) {
                        props.setProjectItem((oldValue) => {
                          const oldArray = oldValue.projectStages
                            ? oldValue.projectStages
                            : [];
                          props.showEntryModal(false);
                          return {
                            ...oldValue,
                            projectStages: [
                              ...oldArray,
                              projectStage,
                              // .map((item) => {
                              //     return {
                              //         ...item,
                              //         plannedStartDate: item.plannedStartDate? item.plannedStartDate : '',
                              //         plannedEndDate: item.plannedEndDate? item.plannedEndDate: '',
                              //         actualStartDate: item.actualStartDate? actualStartDate: '',
                              //         actualEndDate: item.actualEndDate? item.actualEndDate: ''
                              //     };
                              // })
                            ],
                          };
                        });
                        setProjectStage(DEFAULT_PROJECT_STAGE);
                      }
                    }}
                  >
                    Add project stage
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
            {props.stepFooter ? props.stepFooter(props) : <></>}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ProjectStageDetails;
