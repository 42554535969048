import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import TheLayout from "./containers/TheLayout";
import { getCache } from "./helpers/CacheHelper";
import EventSystem from "./helpers/EventSystem";
import { checkIsLoggedIn, logout, updateLastAction } from "./helpers/Utils";
import { updateCache } from "./redux/slicers/GlobalSlicer";
import "./scss/style.scss";
import Login from "./views/pages/login/Login";
import Page404 from "./views/pages/page404/Page404";

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.globalSlicer.isLoggedIn);
  useEffect(() => {
    if (window.location.pathname !== "#/" && isLoggedIn) {
      checkIsLoggedIn(dispatch);
      getCache().then((cache) => dispatch(updateCache(cache)));
    }
    EventSystem.subscribe("UNAUTHORIZED_ACTION", () => logout(dispatch));
    document.addEventListener("click", updateLastAction, false);  
    return () => {
      EventSystem.unsubscribe("UNAUTHORIZED_ACTION");
      document.removeEventListener("click", updateLastAction, false);
    };
  });

  return (
    <>
      <HashRouter>
        {/* <React.Suspense fallback={loading}> */}
        <Switch>
          {!isLoggedIn ? (
            <Login />
          ) : (
            <>
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />

              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </>
          )}
        </Switch>
        {/* </React.Suspense> */}
      </HashRouter>
    </>
  );
};

export default App;
