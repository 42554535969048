
import * as axios from 'axios';
import EventSystem from './EventSystem';
import { readBlobAsJson } from './Utils';

export function registerInterceptors() {
  axios.interceptors.request.use(
    config => {
      const { url } = config;

      if (!url.endsWith('auth/login')) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${localStorage.getItem('AUTHORIZATION')}`
        };
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      // console.log(error.request.responseURL,error);
      // if (error.response.status === 401) {
      //   if (!error.request.responseURL.endsWith('auth/login')) {
      //     EventSystem.publish('UNAUTHORIZED_ACTION');
      //     return new Promise(() => { });
      //   }
      // } else if (error.response.status === 403) {
      //   EventSystem.publish('UNAUTHORIZED_RESOURCE');
      // }

      if (error.response.status === 404 && error.config.responseType === 'blob') {
        error.response.data = await readBlobAsJson(error.response.data);
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
}
