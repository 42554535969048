const navigation = [
  {
    _tag: "CSidebarNavDropdown",
    name: "Taxes",
    icon: "cil-money",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Vat",
        to: "/vat",
        icon: "cil-calculator",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Form10",
        to: "/form10",
        icon: "cil-calculator",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Operations",
    icon: "cil-applications-settings",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Certificates Generation",
        to: "/certificates/generate",
        icon: "cil-file",
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Project Management",
    icon: "cil-briefcase",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Clients list",
        to: "/client",
        icon: "cil-people",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Employees list",
        to: "/employee",
        icon: "cil-people",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Projects list",
        to: "/project",
        icon: "cil-list",
      },
    ],
  },
];
export default navigation;
