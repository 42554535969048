import {
  CBreadcrumbRouter, CHeader, CHeaderNav,
  CSubheader, CToggler
} from '@coreui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSidebarShow } from '../redux/slicers/GlobalSlicer'
// routes config
import routes from '../routes'
import {
  TheHeaderDropdown
} from './index'



const TheHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.globalSlicer.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
    dispatch(updateSidebarShow(val));
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />

      <CHeaderNav className="d-md-down-none mr-auto">
        {/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink>Settings</CHeaderNavLink>
        </CHeaderNavItem> */}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <TheHeaderDropdown />
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </CSubheader>
    </CHeader>
  )
}

export default TheHeader
