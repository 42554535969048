import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: localStorage.getItem("AUTHORIZATION") != null,
  sidebarShow: "responsive",
  filteredNavigations: JSON.parse(
    localStorage.getItem("AVAILABLE_NAVS")
      ? localStorage.getItem("AVAILABLE_NAVS")
      : "[]"
  ),
  cache: {},
};

export const globalSlicer = createSlice({
  name: "globalSlicer",
  initialState,
  reducers: {
    updateIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    updateSidebarShow: (state, action) => {
      state.sidebarShow = action.payload;
    },
    updateFilteredNavigations: (state, action) => {
      state.filteredNavigations = action.payload;
    },
    updateCache: (state, action) => {
      state.cache = action.payload;
    },
  },
});

export const {
  updateIsLoggedIn,
  updateSidebarShow,
  updateFilteredNavigations,
  updateCache,
} = globalSlicer.actions;

export default globalSlicer.reducer;
