import {
  AuthenticationApi,
  CacheApi,
  CertificatesApi,
  ClientsApi,
  Configuration, EmployeesApi, ProjectsApi,
  TaxApi
} from "../swagger";

export const baseUrl = process.env.REACT_APP_BASE_URL;
const config = new Configuration({ basePath: baseUrl });

export const TaxAPI = new TaxApi(config);
export const AuthenticationAPI = new AuthenticationApi(config);
export const CertificatesAPI = new CertificatesApi(config);
export const ClientsAPI = new ClientsApi(config);
export const ProjectsAPI = new ProjectsApi(config);
export const CacheAPI = new CacheApi(config);
export const EmployeesAPI = new EmployeesApi(config);
