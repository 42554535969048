import * as XLSX from "xlsx";
export const readCertificatesFile = (e, callback) => {
  e.preventDefault();

  var files = e.target.files,
    f = files[0];
  var reader = new FileReader();
  reader.onload = function (e) {
    var data = e.target.result;
    let readData = XLSX.read(data, {
      type: "binary",
      cellText: false,
      cellDates: true,
    });
    const names = readData.SheetNames[0];
    const namesData = readData.Sheets[names];

    /* Convert array to json*/
    const dataParse = XLSX.utils.sheet_to_json(namesData, {
      header: ["title","name"],
      blankrows: false
    });
    const attendees = dataParse.slice(1);

    const course = readData.SheetNames[1];
    const courseData = readData.Sheets[course];
    const courseParsedData = XLSX.utils.sheet_to_json(courseData, {
      header: 1,
      raw: false,
      dateNF: "yyyy-mm-dd",
    });
    // console.log(courseParsedData);
    
    let formDetails = {
      courseName: courseParsedData[0][1],
      courseLocation: courseParsedData[1][1],
      fromDate: new Date(courseParsedData[2][1]),
      toDate: new Date(courseParsedData[3][1]),
      isArabic: true,
      isOneDay: courseParsedData[2][1]===courseParsedData[3][1],
      isAttend: true,
      standards: [courseParsedData[4][1],courseParsedData[5][1],courseParsedData[6][1],courseParsedData[7][1]],
      attendees: attendees,
    };
    callback(formDetails);
  };
  reader.readAsBinaryString(f);
  reader.onerror=function(e){
    console.log(e);
  }
};
